export const presets = {
  moveToLeftFromRight: {
    exit: {
      name: "moveToLeft",
    },
    enter: {
      name: "moveFromRight",
    },
  },
  moveToRightFromLeft: {
    exit: {
      name: "moveToRight",
    },
    enter: {
      name: "moveFromLeft",
    },
  },
};
// View the full list of available presets here https://github.com/Steveeeie/react-page-transition/blob/master/src/lib/presets.js
