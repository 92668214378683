import { keyframes } from "styled-components";

export const animations = {
  moveToLeft: {
    keyframes: keyframes`
      from { }
      to { transform: translateX(-100%) rotateZ(0.01deg); }
    `,
    duration: 350,
    easing: "cubic-bezier(.42,0,.58,1)",
    fill: "both",
  },
  moveFromLeft: {
    keyframes: keyframes`
      from {  transform: translateX(-100%) rotateZ(0.01deg); }
    `,
    duration: 350,
    easing: "cubic-bezier(.42,0,.58,1)",
    fill: "both",
  },
  moveToRight: {
    keyframes: keyframes`
      from { }
      to { transform: translateX(100%) rotateZ(0.01deg); }
    `,
    duration: 350,
    easing: "cubic-bezier(.42,0,.58,1)",
    fill: "both",
  },
  moveFromRight: {
    keyframes: keyframes`
      from { transform: translateX(100%) rotateZ(0.01deg); }
    `,
    duration: 350,
    easing: "cubic-bezier(.42,0,.58,1)",
    fill: "both",
  },
};
// View the full list of animations here https://github.com/Steveeeie/react-page-transition/blob/master/src/lib/animations.js
