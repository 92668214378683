import React from "react";
import CardHeader from "./CardHeader";
import StepsCard from "./cards/StepsCard";
import CaloriesCard from "./cards/CaloriesCard";
import WorkoutCard from "./cards/WorkoutCard";
import OdometerCard from "./cards/OdometerCard";
import HeartRateCard from "./cards/HeartRateCard";
import SleepCard from "./cards/SleepCard";
import HrvCard from "./cards/HrvCard"
import { getDisplayName } from "../Utility";
import RhrCard from "./cards/RhrCard";
import SleepHelpModal from "./SleepHelpModal";

export default function Card({ title, setModal }) {
  let icon;
  switch (title) {
    case "steps":
      icon = (
        <img
          className="workout-icon"
          src="/static/steps.svg"
          alt="foot"
          height={18}
        />
      );
      return (
        <div className="card">
          <CardHeader title={getDisplayName(title)} icon={icon} />
          <StepsCard />
        </div>
      );
    case "workout_time":
      icon = (
        <img
          className="workout-icon"
          src="/static/strength.svg"
          alt="strength"
          height={18}
        />
      );
      return (
        <div className="card">
          <CardHeader title={getDisplayName(title)} icon={icon} />
          <WorkoutCard title={title} />
        </div>
      );
    case "running":
      icon = (
        <img
          className="workout-icon"
          src="/static/running.svg"
          alt="running"
          height={18}
        />
      );
      return (
        <div className="card">
          <CardHeader title={getDisplayName(title)} icon={icon} />
          <WorkoutCard title={title} />
        </div>
      );
    case "cycling":
      icon = (
        <img
          className="workout-icon"
          src="/static/cycling.svg"
          alt="cycling"
          height={18}
        />
      );
      return (
        <div className="card">
          <CardHeader title={getDisplayName(title)} icon={icon} />
          <WorkoutCard title={title} />
        </div>
      );
    case "swim":
      icon = (
        <img
          className="workout-icon"
          src="/static/swim.svg"
          alt="swim"
          height={18}
        />
      );
      return (
        <div className="card">
          <CardHeader title={getDisplayName(title)} icon={icon} />
          <WorkoutCard title={title} />
        </div>
      );
    case "strength":
      icon = (
        <img
          className="workout-icon"
          src="/static/strength.svg"
          alt="strength"
          height={18}
        />
      );
      return (
        <div className="card">
          <CardHeader title={getDisplayName(title)} icon={icon} />
          <WorkoutCard title={title} />
        </div>
      );
    case "calories":
      icon = (
        <img
          className="workout-icon"
          src="/static/fire.svg"
          alt="calories"
          height={18}
        />
      );
      return (
        <div className="card">
          <CardHeader title={getDisplayName(title)} icon={icon} />
          <CaloriesCard />
        </div>
      );
    case "odometer":
      icon = (
        <img
          className="workout-icon"
          src="/static/odometer.svg"
          alt="odometer"
          height={18}
        />
      );
      return (
        <div className="card">
          <CardHeader title={getDisplayName(title)} icon={icon} />
          <OdometerCard />
        </div>
      );
    case "heart_rate":
      icon = (
        <img
          className="workout-icon"
          src="/static/heart.svg"
          alt="heart"
          height={18}
        />
      );
      return (
        <div className="card">
          <CardHeader title={getDisplayName(title)} icon={icon} />
          <HeartRateCard />
        </div>
      );
    case "sleep":
      icon = (
        <img
          className="workout-icon"
          src="/static/sleep.svg"
          alt="heart"
          height={18}
        />
      );

      const iconComponent = (
        <img
          src="/static/info_circle.svg"
          alt="help"
          height={30}
          onClick={() => setModal(<SleepHelpModal/>)}
        />
      );

      return (
        <div className="card">
          <CardHeader title={getDisplayName(title)} icon={icon} RightIconComponent={iconComponent} betaLabel={true} />
          <SleepCard />
        </div>
      );
      case "hrv":
        icon = (
            <img
                className="workout-icon"
                src="/static/hrv.svg"
                alt="heart"
                height={18}
            />
        );
        return (
            <div className="card">
                <CardHeader title={getDisplayName(title)} icon={icon} />
                <HrvCard />
            </div>
        )
      case "rhr":
          icon = (
              <img
                  className="workout-icon"
                  src="/static/heart.svg"
                  alt="heart"
                  height={18}
              />
          );
          return (
              <div className="card">
                  <CardHeader title={getDisplayName(title)} icon={icon}/>
                  <RhrCard />
              </div>
          )
    default:
      return <div></div>;
  }
}
