import {
  max,
  keys,
  sumBy,
  each,
  pick,
  flatten,
  remove,
  sortBy,
  last,
  first,
} from "lodash-es";
//import I18n from "../../packs/statistics.js.erb";
import I18n from "./Translation";
import DataService from "./DataService";

// Helper Methods

export const dayCodeToDate = (day_code, style = false, omitYear = false) => {
  let date1 = new Date("01/01/2020");
  date1.setDate(date1.getDate() + day_code);
  if (style === true) {
    if (dateToDayCode(new Date()) === day_code) {
      return I18n.t("javascript.statistics.utility.today");
    }
    if (I18n.currentLocale() === "en") {
      let dateStrings = date1
        .toLocaleString("en-US", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        })
        .replace(/,/g, "")
        .split(" ");
      let weekday = I18n.t("date.day_names")[date1.getDay()];
      let day = dateStrings[2];
      let month = I18n.t("date.abbr_month_names")[date1.getMonth() + 1];
      let year = dateStrings[3];
      return omitYear
        ? `${weekday}, ${day} ${month}`
        : `${weekday}, ${day} ${month} ${year}`;
    }
    return date1.toLocaleString([I18n.currentLocale()], {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  } else {
    return date1;
  }
};

export const dayCodeToWeekday = (day_code) => {
  let abbr_days = I18n.t("date.abbr_day_names");
  let dayLetters = [];
  abbr_days.forEach((str) => {
    dayLetters.push(str.charAt(0).toLowerCase());
  });
  return dayLetters[dayCodeToDate(day_code).getDay()];
};

export const getWeekRangeForDayCode = (day_code) => {
  let weekdayCode = dayCodeToDate(day_code).getDay();
  let first;
  let last;
  switch (weekdayCode) {
    case 0:
      first = day_code - 6;
      last = day_code;
      break;
    case 1:
      first = day_code;
      last = day_code + 6;
      break;
    default:
      first = day_code - (weekdayCode - 1);
      last = first + 6;
      break;
  }
  return { first, last };
};

export const dayCodeToISO = (day_code) => {
  let date1 = new Date("01/01/2020");
  date1.setDate(date1.getDate() + day_code);
  return date1.toISOString();
};

export const dateToDayCode = (date) => {
  let date1 = new Date("01/01/2020");
  let date2 = new Date(date);
  return Math.floor((date2 - date1) / 86400000);
};

export const currentDayCode = () => {
  return dateToDayCode(new Date());
};

export const metersToMiles = (meters) => {
  return meters * 0.00062137119224;
};

export const metersToYards = (meters) => {
  return meters * 1.09361;
};

export const yardsToMeters = (yards) => {
  return yards * 0.9144;
};

export const milesToMeters = (miles) => {
  return miles * 1609.344;
};

export const metersToFeet = (meters) => {
  return meters * 3.2808399;
};

export const metersToKm = (meters) => {
  return meters * 0.001;
};

export const kmToMeters = (km) => {
  return km * 1000;
};

export const minutesToHours = (duration) => {
  return Math.floor(duration / 60);
};
export const minutesToDecimal = (duration) => {
  return duration / 60;
};
export const hoursToMinutes = (duration) => {
  return Math.floor(duration * 60);
};

export const stripTime = (timeStr) => {
  if(timeStr.charAt(0) === '0'){
    return timeStr.substring(1).replace(/\s/g, '').toLowerCase();
  }
  return timeStr.replace(/\s/g, "").toLowerCase();
};

export const msToTime = (duration, longFormat) => {
  let minutes = Math.floor((duration / (1000 * 60)) % 60);
  let hours = Math.floor(duration / (1000 * 60 * 60));
  if (longFormat) {
    if (hours < 1) {
      return I18n.t("javascript.statistics.utility.time", {
        minutes: `${minutes.toString()}`,
      });
    }
    return I18n.t("javascript.statistics.utility.time_long", {
      minutes: `${minutes.toString()}`,
      hours: `${hours.toString()}`,
    });
  }
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return `${hours}:${minutes}`;
};

export const minutesToTime = (duration, format = "") => {
  const minutes = Math.floor(duration % 60);
  const hours = Math.floor(duration / 60);

  if (format === "long") {
    if (hours < 1) {
      return I18n.t("javascript.statistics.utility.time", {
        minutes: minutes.toString(),
      });
    }
    return I18n.t("javascript.statistics.utility.time_long", {
      minutes: minutes.toString(),
      hours: hours.toString(),
    });
  } else if (format === "medium") {
    if (minutes === 0) {
      return I18n.t("javascript.statistics.utility.time_hours", {
        hours: hours.toString(),
      });
    }
    return I18n.t("javascript.statistics.utility.time_medium", {
      minutes: minutes.toString(),
      hours: hours.toString(),
    });
  } else if (format === "with_zero") {
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  } else {
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  }
};

export const iOS_version = () => {
  if (navigator.userAgent.match(/ipad|iphone|ipod/i)) {
    //if the current device is an iDevice
    let ios_info = {};
    ios_info.User_Agent = navigator.userAgent;
    ios_info.Full_Release_Numeric = +navigator.userAgent
      .match(/OS (\d)?\d_\d(_\d)?/i)[0]
      .replace("_", ".")
      .replace("_", "")
      .replace("OS ", ""); //converts versions like 4.3.3 to numeric value 4.33 for ease of numeric comparisons
    return ios_info;
  }
};

export const getOS = () => {
  let userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;
  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "mac";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "ios";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "windows";
  } else if (/Android/.test(userAgent)) {
    os = "android";
  } else if (!os && /Linux/.test(platform)) {
    os = "linux";
  }
  return os;
};

export const prefersDarkMode = () => {
  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    return true;
  }
};

export const getDisplayName = (name, isSettingsPage) => {
  switch (name) {
    case "steps":
      return I18n.t("javascript.statistics.utility.steps");
    case "workout_time":
      return I18n.t("javascript.statistics.utility.workout_time");
    case "running":
      return isSettingsPage
        ? I18n.t("javascript.statistics.statistics_page.weekly_running")
        : I18n.t("javascript.statistics.utility.running");
    case "cycling":
      return isSettingsPage
        ? I18n.t("javascript.statistics.statistics_page.weekly_cycling")
        : I18n.t("javascript.statistics.utility.cycling");
    case "swim":
      return isSettingsPage
        ? I18n.t("javascript.statistics.statistics_page.weekly_swimming")
        : I18n.t("javascript.statistics.utility.swimming");
    case "strength":
      return isSettingsPage
        ? I18n.t("javascript.statistics.statistics_page.weekly_strength")
        : I18n.t("javascript.statistics.utility.strength");
    case "calories":
      return I18n.t("javascript.statistics.utility.calories");
    case "odometer":
      return I18n.t("javascript.statistics.statistics_page.total_odometer");
    case "heart_rate":
      return `${I18n.t("javascript.statistics.statistics_page.full_day_hr")}`;
    case "sleep":
      return I18n.t("javascript.statistics.statistics_page.sleep");
    case "hrv":
      return I18n.t("javascript.statistics.statistics_page.hrv");
    case "rhr":
      return I18n.t("javascript.statistics.statistics_page.resting_hr");
    default:
      return "";
  }
};

export const getGoalName = (name) => {
  switch (name) {
    case "steps":
      return "daily_steps";
    case "workout_time":
      return "weekly_active_time";
    case "running":
      return "weekly_run_distance";
    case "cycling":
      return "weekly_bike_distance";
    case "swim":
      return "weekly_swim_distance";
    case "calories":
      return "daily_calories";
    case "sleep":
      return "daily_sleep";
    default:
      return null;
  }
};

export const getGoalValue = (name, value, displayPreference) => {
  let imperial = displayPreference.speed_distance === "imperial";
  switch (name) {
    case "running":
      return imperial ? metersToMiles(value) : metersToKm(value);
    case "cycling":
      return imperial ? metersToMiles(value) : metersToKm(value);
    case "swim":
      return imperial ? metersToYards(value) : value;
    case "workout_time":
      return minutesToHours(value);
    case "sleep":
      return value;
    default:
      return value;
  }
};

export const getTargetGoalForDB = (name, value, displayPreference) => {
  let imperial = displayPreference.speed_distance === "imperial";
  switch (name) {
    case "running":
      return imperial ? milesToMeters(value) : kmToMeters(value);
    case "cycling":
      return imperial ? milesToMeters(value) : kmToMeters(value);
    case "swim":
      return imperial ? yardsToMeters(value) : value;
    case "workout_time":
      return hoursToMinutes(value);
    case "sleep":
      return value;
    default:
      return value;
  }
};

export const getGoalLabel = (name, displayPreference) => {
  let imperial = displayPreference.speed_distance === "imperial";
  switch (name) {
    case "steps":
      return I18n.t("javascript.statistics.utility.daily_goal");
    case "workout_time":
      return I18n.t("javascript.statistics.utility.weekly_goal", {
        units: I18n.t("javascript.statistics.utility.hr_abbr"),
      });
    case "running":
      return imperial
        ? I18n.t("javascript.statistics.utility.weekly_goal", {
            units: I18n.t("javascript.statistics.utility.mi_abbr"),
          })
        : I18n.t("javascript.statistics.utility.weekly_goal", {
            units: I18n.t("javascript.statistics.utility.km_abbr"),
          });
    case "cycling":
      return imperial
        ? I18n.t("javascript.statistics.utility.weekly_goal", {
            units: I18n.t("javascript.statistics.utility.mi_abbr"),
          })
        : I18n.t("javascript.statistics.utility.weekly_goal", {
            units: I18n.t("javascript.statistics.utility.km_abbr"),
          });
    case "swim":
      return imperial
        ? I18n.t("javascript.statistics.utility.weekly_goal", {
            units: I18n.t("javascript.statistics.utility.yd_abbr"),
          })
        : I18n.t("javascript.statistics.utility.weekly_goal", {
            units: I18n.t("javascript.statistics.utility.m_abbr"),
          });
    case "calories":
      return I18n.t("javascript.statistics.utility.daily_goal_calories");
    case "sleep":
      return I18n.t("javascript.statistics.utility.daily_goal");
    default:
      return "";
  }
};

export const getBarColor = (total, goal) => {
  let hasGoal = true;
  if (parseInt(goal) === 0) {
    goal = 10000;
    hasGoal = false;
  }
  total ||= 0;
  let inWorkoutColor;
  let totalColor;
  if (total === 0) {
    inWorkoutColor = "#A0AAB1";
    totalColor = "#A0AAB1";
  } else if (total < goal) {
    inWorkoutColor = hasGoal ? "#A15700" : "#007FAA";
    totalColor = hasGoal ? "#DE9332" : "#007FAA";
  } else {
    inWorkoutColor = hasGoal ? "#006310" : "#007FAA";
    totalColor = hasGoal ? "#329C45" : "#007FAA";
  }
  return { inWorkoutColor, totalColor };
};

export const getGoalLineHeight = (
  maxReading,
  setMaxReading,
  goal,
  cardType,
  upperBound
) => {
  if (maxReading > upperBound) {
    setMaxReading(upperBound);
    if (cardType === "calories") {
      maxReading = upperBound;
    }
  }
  if (cardType === "steps") {
    if (maxReading > goal - 500 && maxReading < upperBound) {
      setMaxReading(maxReading + 4000); // if the max value is close to the goal, increase the max value
      maxReading = maxReading + 4000;
    }
  }
  if (cardType === "calories") {
    if (maxReading > goal - 200 && maxReading < upperBound) {
      setMaxReading(maxReading + 1000);
      maxReading = maxReading + 1000;
    }
  }
  let maxBarHeight = 95;
  let minBarHeight = 8;
  let percentage = goal / maxReading;
  if (percentage > 0.95) {
    percentage = 0.95;
  }
  // 6 pixels is the minimum required space under the top line
  return Math.abs(
    maxBarHeight +
      6 -
      max([Math.floor(percentage * maxBarHeight), minBarHeight])
  );
};

export const getBarHeight = (
  count,
  maxReading,
  setMaxReading,
  goal,
  cardType,
  upperBound
) => {
  if (maxReading > upperBound) {
    setMaxReading(upperBound);
  }
  let bottom = maxReading;
  if (goal >= maxReading) {
    bottom = goal;
  }
  let maxBarHeight = 95;
  let minBarHeight = 8;
  let height;
  let percentage = count / bottom;
  if (percentage > 1.0) {
    height = maxBarHeight;
  } else {
    height = max([Math.floor(percentage * maxBarHeight), minBarHeight]);
  }
  return { height };
};

export const getWorkoutBarStyle = (obj, cardType, maxValue) => {
  const maxBarHeight = 95;
  const minBarHeight = 8;
  let height;
  let percentage;
  let color = "#007FAA";
  switch (cardType) {
    case "running":
      // 8 miles running a day is a full bar
      percentage =
        metersToMiles(maxValue) > 8
          ? obj.distance / maxValue
          : metersToMiles(obj.distance) / 8;
      if (obj.distance === 0) {
        color = "#A0AAB1";
      }
      break;
    case "cycling":
      // 20 miles cycling a day is a full bar
      percentage =
        metersToMiles(maxValue) > 20
          ? obj.distance / maxValue
          : metersToMiles(obj.distance) / 20;
      if (obj.distance === 0) {
        color = "#A0AAB1";
      }
      break;
    case "swim":
      // 3000 yards swimming a day is a full bar
      percentage =
        metersToYards(maxValue) > 3000
          ? obj.distance / maxValue
          : metersToYards(obj.distance) / 3000;
      if (obj.distance === 0) {
        color = "#A0AAB1";
      }
      break;
    case "strength":
      // one hour gym for a day is a full bar
      percentage =
        maxValue > 3600000 ? obj.time / maxValue : obj.time / 3600000;
      if (obj.time === 0) {
        color = "#A0AAB1";
      }
      break;
    case "workout_time":
      // three hours working out in a day is a full bar
      percentage =
        maxValue > 10800000 ? obj.time / maxValue : obj.time / 10800000;
      if (obj.time === 0) {
        color = "#A0AAB1";
      }
      break;
    default:
      percentage = 0;
  }
  if (percentage > 1.0) {
    height = maxBarHeight;
  } else {
    height = max([Math.floor(percentage * maxBarHeight), minBarHeight]);
  }
  return { height, color };
};

export const getWeeklyDistanceFormat = (
  totalDistance,
  targetGoals,
  title,
  displayPreference
) => {
  let current;
  let goal;
  switch (title) {
    case "swim":
      if (displayPreference.speed_distance === "imperial") {
        current = metersToYards(totalDistance);
        goal = metersToYards(targetGoals.weekly_swim_distance);
      } else {
        current = totalDistance;
        goal = targetGoals.weekly_swim_distance;
      }
      break;
    case "workout_time":
      if (displayPreference.speed_distance === "imperial") {
        current = metersToMiles(totalDistance);
      } else {
        current = metersToKm(totalDistance);
      }
      break;
    case "running":
      if (displayPreference.speed_distance === "imperial") {
        current = metersToMiles(totalDistance);
        goal = metersToMiles(targetGoals.weekly_run_distance);
      } else {
        current = metersToKm(totalDistance);
        goal = metersToKm(targetGoals.weekly_run_distance);
      }
      break;
    case "cycling":
      if (displayPreference.speed_distance === "imperial") {
        current = metersToMiles(totalDistance);
        goal = metersToMiles(targetGoals.weekly_bike_distance);
      } else {
        current = metersToKm(totalDistance);
        goal = metersToKm(targetGoals.weekly_bike_distance);
      }
      break;
  }
  return { current, goal };
};

export const getDistanceDisplay = (title, displayPreference) => {
  let distance;
  let yd = I18n.t("javascript.statistics.utility.yd_abbr");
  let m = I18n.t("javascript.statistics.utility.m_abbr");
  let mi = I18n.t("javascript.statistics.utility.mi_abbr");
  let km = I18n.t("javascript.statistics.utility.km_abbr");
  if (title === "swim") {
    distance = displayPreference.speed_distance === "imperial" ? yd : m;
  } else {
    distance = displayPreference.speed_distance === "imperial" ? mi : km;
  }
  return I18n.t("javascript.statistics.utility.distance_abbr", {
    abbr: distance,
  });
};

export const getDailyDistanceFormat = (
  meters,
  title,
  displayPreference,
  shortMiles = false,
  milesDecimals
) => {
  switch (title) {
    case "swim":
      if (displayPreference.speed_distance === "imperial") {
        let distance = metersToYards(meters).toLocaleString(undefined, {
          maximumFractionDigits: 0,
        });
        return I18n.t("javascript.statistics.utility.yards", {
          distance: `${distance.toString()}`,
        });
      }
      return I18n.t("javascript.statistics.utility.meters", {
        distance: `${meters.toString()}`,
      });
    default:
      if (displayPreference.speed_distance === "imperial") {
        let distance = metersToMiles(meters).toLocaleString(undefined, {
          maximumFractionDigits: milesDecimals ? milesDecimals : 2,
        });
        return shortMiles
          ? I18n.t("javascript.statistics.utility.miles_short", {
              distance: `${distance.toString()}`,
            })
          : I18n.t("javascript.statistics.utility.miles", {
              distance: `${distance.toString()}`,
            });
      }
      let distance = metersToKm(meters).toLocaleString(undefined, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      });
      return I18n.t("javascript.statistics.utility.km", {
        distance: `${distance.toString()}`,
      });
  }
};

export const getAscentFormat = (totalAscent, displayPreference) => {
  if (displayPreference.elevation === "imperial") {
    let distance = metersToFeet(totalAscent).toLocaleString(undefined, {
      maximumFractionDigits: 0,
    });
    return I18n.t("javascript.statistics.utility.feet_short", {
      distance: `${distance.toString()}`,
    });
  }
  let distance = totalAscent.toLocaleString(undefined, {
    maximumFractionDigits: 0,
  });
  return I18n.t("javascript.statistics.utility.meters_short", {
    distance: `${distance.toString()}`,
  });
};

export const workoutFamilies = {
  cycling: [0, 11, 12, 13, 14, 15, 16, 61, 49, 64, 68],
  other: [17, 18, 19, 45, 46, 47, 2, 62, 21, 22, 23, 57, 58, 59, 60, 63, 65],
  swimming: [24, 25, 26],
  running: [1, 3, 4, 5, 67],
  walking: [6, 7, 8, 56, 9, 10],
  gym: [20, 42, 43, 44, 66],
  snow_sports: [27, 28, 29, 30],
  skating: [31, 32, 33, 34],
  water_sports: [35, 36, 37, 38, 39, 40, 41],
};

export const getWorkoutFamily = (workoutTypeId) => {
  let workoutFamily = "other";
  keys(workoutFamilies).forEach((key) => {
    if (workoutFamilies[key].includes(workoutTypeId)) {
      workoutFamily = key;
    }
  });
  return workoutFamily;
};

export const getWorkoutFamilyIconName = (title) => {
  switch (title) {
    case "cycling":
      return "cycling";
    case "other":
      return "other";
    case "swimming":
      return "swim";
    case "running":
      return "running";
    case "walking":
      return "walking";
    case "gym":
      return "strength";
    case "snow_sports":
      return "snow_sports";
    case "skating":
      return "skating";
    case "water_sports":
      return "kayaking";
    default:
      return "other";
  }
};

export const getWorkoutIconName = (workoutTypeId) => {
  let validTypes = [
    0, 1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 19, 20, 21, 22,
    23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
    42, 43, 44, 45, 46, 47, 49, 56, 61, 64, 66, 67, 68,
  ];
  let iconNames = [
    "cycling",
    "running",
    "track_running",
    "trail_running",
    "treadmill",
    "walking",
    "speed_walking",
    "nordic_walking",
    "hiking",
    "mountaineering",
    "cyclecross",
    "indoor_biking",
    "mountain_biking",
    "recumbent",
    "road_biking",
    "track_biking",
    "motocycling",
    "treadmill",
    "elliptical",
    "bike",
    "rower",
    "climber",
    "swimming",
    "lap_swimming",
    "open_water_swimming",
    "snowboarding",
    "skiing",
    "dh_ski",
    "xc_ski",
    "skating",
    "ice_skating",
    "inline_skating",
    "longboarding",
    "sailing",
    "windsurfing",
    "canoeing",
    "kayaking",
    "rowing",
    "kiteboarding",
    "SUP",
    "strength",
    "cardio_class",
    "stair_climber",
    "wheelchair",
    "golfing",
    "other",
    "indoor_biking",
    "treadmill_walking",
    "indoor_biking",
    "e_biking",
    "yoga",
    "race_running",
    "indoor_biking",
  ];
  if (validTypes.includes(workoutTypeId)) {
    return iconNames[validTypes.indexOf(workoutTypeId)];
  }
  return "other";
};

export const hrGraphConfig = {
  library: {
    events: [],
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      y: {
        grid: {
          borderDash: [10],
          drawTicks: false,
          drawBorder: true,
        },
        max: 200,
        min: 10,
        ticks: {
          autoSkip: false,
          stepSize: 50,
          maxTicks: 5,
          maxTicksLimit: 5,
          callback: function (value, index, values) {
            if (index === 0) {
              return "";
            }
            return value;
          },
        },
      },
      y1: {
        position: "right",
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          drawTicks: false,
        },
      },
      x: {
        min: 0,
        max: 1440,
        ticks: {
          beginAtZero: true,
          maxTicks: 5,
          maxTicksLimit: 5,
          stepSize: 360,
          callback: function (value, index, values) {
            if (index === 0) {
              return "12am";
            }
            if (index === 360) {
              return "6am";
            }
            if (index === 720) {
              return "12pm";
            }
            if (index === 1080) {
              return "6pm";
            }
          },
        },
      },
    },
  },
};

export const applyZones = (currentData, heartRateZones) => {
  if (sumBy(currentData, "heartrate_bpm") < 100) {
    // if total bpm for 24 hrs is less than 100, this is no data
    return [0, 0, 0, 0, 0];
  } else {
    let zoneCounts = [0, 0, 0, 0, 0];
    let zones = heartRateZones;
    each(currentData, function (o, i) {
      if (o.heartrate_bpm <= zones.zone_1) {
        zoneCounts[4]++;
      } else if (
        o.heartrate_bpm > zones.zone_1 &&
        o.heartrate_bpm <= zones.zone_2
      ) {
        zoneCounts[3]++;
      } else if (
        o.heartrate_bpm > zones.zone_2 &&
        o.heartrate_bpm <= zones.zone_3
      ) {
        zoneCounts[2]++;
      } else if (
        o.heartrate_bpm > zones.zone_3 &&
        o.heartrate_bpm <= zones.zone_4
      ) {
        zoneCounts[1]++;
      } else if (o.heartrate_bpm > zones.zone_4) {
        zoneCounts[0]++;
      }
    });
    return zoneCounts;
  }
};

export const getTooltipCoordinates = ({ left, top, width, height }) => {
  let newLeft = left;
  let newTop = top;
  let minTop = 0;
  let maxTop = 247;
  let minLeft = 77;
  let maxLeft = 265;

  let centerX = left + width / 2;
  let centerY = top + height / 2;
  if (centerX < 200) {
    newLeft = minLeft;
  } else if (centerX >= 200) {
    newLeft = maxLeft;
  }
  if (centerY < 145) {
    newTop = minTop;
  } else if (centerY >= 145) {
    newTop = maxTop;
  }

  return { newLeft, newTop };
};

export const getChartWidth = (size, chartCount) => {
  switch (chartCount) {
    case 7:
      return size * 2.5;
    case 14:
      return size * 2.3;
    case 30:
      return size * 2.2;
    default:
    // do nothing
  }
};

export const getChartInterval = (chartCount, size) => {
  switch (chartCount) {
    case 7:
      return { interval: size / (chartCount - 0.32), nullCount: 4 };
    case 14:
      return { interval: size / (chartCount - 0.43), nullCount: 7 };
    case 30:
      return { interval: size / (chartCount + 0.6), nullCount: 15 };
    default:
    // do nothing
  }
};

export const getPlaceHolderX = (chartCount, size) => {
  switch (chartCount) {
    case 7:
      return Math.round(size * 0.8);
    case 14:
      return Math.round(size * 0.67);
    case 30:
      return Math.round(size * 0.625);
    default:
    // do nothing
  }
};

export const getWeeklyAverageRating = (days) => {
  let values = { poor: 0, fair: 1, good: 2, great: 3, excellent: 4 };
  let total = 0;
  let ratedDays = days.filter((day) => day.rating);
  ratedDays.forEach((day) => {
    total += values[day.rating];
  });
  if (ratedDays.length) {
    return Math.round(total / ratedDays.length);
  } else return null;
};

export const numberToSleepRating = (number) => {
  let values = ["poor", "fair", "good", "great", "excellent"];
  return values[number];
};

export const sleepRatingToNumber = (rating) => {
  let values = ["poor", "fair", "good", "great", "excellent"];
  return values.indexOf(rating);
};

export const sleepZoneToNumber = (zone) => {
  let values = ['uncategorized', 'deep', 'light', 'rem', 'awake']
  return values.indexOf(zone)
};

export const getSessionDisplay = (date) => {
  return `${date.toLocaleDateString(I18n.currentLocale(), {
    weekday: "short",
  })} ${stripTime(
    date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    })
  )}`;
};

export const validateSleepEdit = async (editDate, session, isStartTime) => {
  let endDate = new Date(session.end_time);
  let startDate = new Date(session.start_time);
  let now = new Date();
  let previousDay = dayCodeToDate(session.day_code - 1);
  let endOfDay = dayCodeToDate(session.day_code);
  endOfDay.setHours(23);
  endOfDay.setMinutes(59);

  // load in sleep data for yesterday and tomorrow
  let nearbySummaries = await DataService.getSleepSummaries(
    session.day_code - 1,
    session.day_code + 1
  );
  let nearbySessions = [];
  if (nearbySummaries.length) {
    // populate nearbySessions with flattened sleep sessions
    nearbySummaries.forEach((s) => {
      let sessions = pick(s, "sleep_sessions");
      nearbySessions.push(sessions["sleep_sessions"]);
    });
    nearbySessions = flatten(nearbySessions);
    nearbySessions = remove(
      nearbySessions.map((obj) =>
        pick(obj, ["start_time", "end_time", "token", "day_code"])
      ),
      (o) => o.token !== session.token
    );
    nearbySessions.forEach((obj) => {
      obj.start_time = new Date(obj.start_time);
      obj.end_time = new Date(obj.end_time);
    });
    let beforeSessions = nearbySessions.filter(
      (obj) => obj.end_time < startDate
    );
    let afterSessions = nearbySessions.filter(
      (obj) => obj.start_time > endDate
    );
    // if there are sessions before and the user is editing the start time
    if (beforeSessions.length && isStartTime) {
      let closest = last(sortBy(beforeSessions, (o) => o.end_time));
      if (editDate < closest.end_time) {
        // start time is before the end_time of another session
        return `${I18n.t(
          "javascript.statistics.statistics_page.session_error_1"
        )} [${getSessionDisplay(closest.start_time)}-${getSessionDisplay(
          closest.end_time
        )}]`;
      }
    }
    // if there are sessions after and the user is editing the end time
    if (afterSessions.length && !isStartTime) {
      let closestStartTime = first(
        sortBy(afterSessions, (o) => o.start_time)
      ).start_time;
      if (editDate > closestStartTime) {
        let closest = last(sortBy(beforeSessions, (o) => o.end_time));
        // end time is after the start_time of another session
        return `${I18n.t(
            "javascript.statistics.statistics_page.session_error_1"
        )} [${getSessionDisplay(closest.start_time)}-${getSessionDisplay(
            closest.end_time
        )}]`;
      }
    }
  }
  // constraints for editing start_time
  if (isStartTime) {
    if (editDate >= now) {
      // start_time cannot be in the future
      return I18n.t("javascript.statistics.statistics_page.session_error_2")
    }
    if (editDate >= endDate) {
      //  start_time must be before end_time
      return `${I18n.t("javascript.statistics.statistics_page.session_error_4")} [${getSessionDisplay(endDate)}]`
    } else if (editDate < previousDay) {
      // start_time cannot be before 12:00 am on day_code previous to session day_code
      return I18n.t("javascript.statistics.statistics_page.session_error_5")
    }
  }
  // constraints for editing end_time
  else {
    if (editDate <= startDate) {
      // end_time must be after start_time
      return `${I18n.t("javascript.statistics.statistics_page.session_error_3")} [${getSessionDisplay(startDate)}]`
    } else if (editDate >= now) {
      // end_time cannot be in the future
      return I18n.t("javascript.statistics.statistics_page.session_error_2")
    } else if (editDate > endOfDay) {
      // end_time cannot be past 11:59 pm on the session day_code
      return I18n.t("javascript.statistics.statistics_page.session_error_6")
    }
  }
  return false;
};
