import React, { useState } from "react";
import { currentDayCode, dayCodeToDate } from "../../Utility";
import I18n from "../../Translation";
import $ from "jquery";

export default function RhrCard() {
  const heartRates = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const [isActive, setIsActive] = useState(true); // scroll container touch event state
  const [currentItem, setCurrentItem] = useState({
    index: 0,
    dayCode: currentDayCode(),
  });
  let emptyData = true;

  const scrollEvent = () => {
    let graphItems = $(".rhr-chart-container .graph-item");
    graphItems.each((index, item) => {
      let graphItemX = item.getBoundingClientRect().x;
      let math = $(window).width() / graphItemX;
      let string = math.toString().slice(0, 3);
      if (string === "2.0") {
        setCurrentItem((currentItem) => {
          return {
            ...currentItem,
            index: index,
            dayCode: currentDayCode() - index,
          };
        });
      }
    });
  };

  const getDotHeight = (index) => {
    let over = heartRates[index] - 30;
    if (over > 0) {
      return over;
    } else {
      return 0;
    }
  };

  if (emptyData) {
    return (
        <div className="rhr-card card-body">
          <div className="p-4">
            {I18n.t("javascript.statistics.statistics_page.empty_rhr_description")}
          </div>
        </div>
    )
  }
  return (
    <div className="rhr-card card-body">
      <div className="card-top-info">
        <div className="date">{dayCodeToDate(currentItem.dayCode, true)}</div>
        <div className="data">
          {I18n.t("javascript.statistics.statistics_page.bpm", {
            bpm: `${heartRates[currentItem.index]}`,
          })}
        </div>
        <div className="arrow-line">
          <div className="bottom-arrow" />
        </div>
      </div>
      <div className="rhr-chart-container">
        <div
          onScroll={() => scrollEvent()}
          className="scroll-container"
          onTouchStart={() => setIsActive(true)}
          onTouchEnd={() => setIsActive(false)}
        >
          <div className="offset">
            <div className="graph-item-container">
              {heartRates.map((obj, index) => {
                return (
                  <div
                    style={{
                      height: "8px",
                      backgroundColor: "#E53935",
                      marginBottom: `${getDotHeight(index)}px`,
                    }}
                    key={index}
                    className="graph-item snap-align-center"
                    data-bpm={obj}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
