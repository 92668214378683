import React from "react";
import ReactDOM from 'react-dom';
import DataService from "./DataService";
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome5/css/fontawesome-all.min.css'
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import Store from "./Store";
import $ from "jquery";
import axios from "axios";
import { remove, minBy, maxBy } from "lodash-es"
import { getOS, prefersDarkMode, iOS_version } from "./Utility";
import NetworkStatus from "./NetworkStatus";
import I18n from "./Translation";
import CloudService from "./CloudService";

const token = window.__token || 'NoToken';
const api = window.__api || "NoAPIHost";

axios.defaults.headers.common["WF-USER-TOKEN"] = token;
axios.defaults.baseURL = api;

const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
});

const locale = params.locale;

export default function Index({ ...props }) {
    console.log("loading index!");

    I18n.locale = props.locale;
    if (prefersDarkMode()) {
        $("body").addClass("dark");
    }
    $("body").addClass(getOS());
    const iOS=iOS_version()
    if(iOS && iOS.Full_Release_Numeric < 15){
        $("body").addClass('ios-scroll-fix')
    }
    DataService.setUserProfile(props.user_profile);
    if(!['alpha', 'dev', 'beta'].includes(props.user_profile)){
        // remove the hrv card if the user is not alpha or dev
        remove(props.user_summary_target.card_settings, function(item) {return item.name === 'hrv'})
        // remove the rhr card if the user is not alpha or dev
        remove(props.user_summary_target.card_settings, function(item) {return item.name === 'rhr'})
    }
    DataService.setUserSummaryTarget(props.user_summary_target);
    DataService.setUserDailySummaries(props.user_daily_summaries);
    let minItem = minBy(props.user_daily_summaries, "day_code");
    DataService.setMinDailySummaryDayCodeLoaded(minItem ? minItem.day_code : 0);
    let maxItem = maxBy(props.user_daily_summaries, "day_code");
    DataService.setMaxDailySummaryDayCodeLoaded(maxItem ? maxItem.day_code : 0);
    DataService.bindMessageBus(props.faye_url, props.user_id);
    DataService.setDisplayPreference(props.display_preference);
    DataService.groupWorkoutTypeSummaries(props.workout_type_summaries);
    DataService.setMinDayBike(props.min_day_bike);
    DataService.setMinDaySwim(props.min_day_swim);
    DataService.setMinDayRun(props.min_day_run);
    DataService.setMinDayGym(props.min_day_gym);
    DataService.setMinDaySleep(props.min_day_sleep);
    DataService.setHeartRateZone(props.heart_rate_zone);
    DataService.setSleepSummaries(props.sleep_summaries);
    DataService.setMinDaySteps(props.min_day_steps);
    let minSleepItem = minBy(props.sleep_summaries, "day_code");
    DataService.setMinSleepDayCodeLoaded(minSleepItem ? minSleepItem.day_code : 720);
    let maxSleepItem = maxBy(props.sleep_summaries, "day_code");
    DataService.setMaxSleepDayCodeLoaded(maxSleepItem ? maxSleepItem.day_code : 720);
    return (
        <Store>
            <Router basename="/statistics">
                <App props={props} />
                <NetworkStatus />
            </Router>
        </Store>
    );
}

CloudService.initialLookup(locale).then(function(data){
    const defProps = data;
    ReactDOM.render(
        <React.StrictMode>
            <Index {...defProps} />
        </React.StrictMode>,
        document.getElementById('root')
    );
});

