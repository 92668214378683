import React, { useContext, useEffect, useState } from "react";
import Switch from "react-switch";
import {
  getDisplayName,
  getGoalLabel,
  getGoalName,
  getGoalValue,
  getOS,
  getTargetGoalForDB,
} from "../Utility";
import { Context } from "../Store";
import DataService from "../DataService";
import $ from "jquery";
import { SortableHandle } from "react-sortable-hoc";
import I18n from "../Translation";
import SleepGoalPicker from "../pickers/SleepGoalPicker";

export default function SettingsItem({ updateList, checked, item }) {
  const hasGoal = (name) => {
    return !!getGoalName(name);
  };
  const OS = getOS() || "ios";
  const [state, dispatch] = useContext(Context);
  const [goalValue, setGoalValue] = useState(
    hasGoal(item.name)
      ? Math.round(
          getGoalValue(
            item.name,
            state.targetGoals[getGoalName(item.name)],
            DataService.displayPreference
          )
        )
      : null
  );
  const updateGoal = (e) => {
    if (e.target) {
      setGoalValue(e.target.value.replace(/\D/, ""));
    } else {
      setGoalValue(e);
    }
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  };

  const DragHandle = SortableHandle(() => (
    <div className="sortable-handle">
      <img
        className="workout-icon"
        src="/static/bars.svg"
        alt="adjust"
        height={12}
      />
    </div>
  ));

  // get database friendly values from km, yards, or miles, update the state obj
  useEffect(() => {
    let converted = Math.round(
      getTargetGoalForDB(item.name, goalValue, DataService.displayPreference)
    );
    let goalName = getGoalName(item.name);
    let newState = { ...state.targetGoals };
    newState[goalName] = converted;
    dispatch({ type: "set_target_goals", payload: newState });
  }, [goalValue]);

  $(function () {
    if (OS === "android") {
      let focusedElement;
      $(document).on("focus", "input", function () {
        if (focusedElement === this) return;
        focusedElement = this;
        setTimeout(function () {
          focusedElement.select();
        });
      });
      $(document).on("blur", "input", function () {
        focusedElement = null;
      });
    }
  });

  // disallow turning off all cards
  let disableSwitch = false;
  let enabled = state.statsCards.filter((card) => card.show);
  if (enabled && enabled.length === 1 && enabled[0].name === item.name) {
    disableSwitch = true;
  }

  return (
    <div className={"settings-item"}>
      <div className="settings-item-top">
        {getDisplayName(item.name, true)}
        <div className="settings-item-actions">
          {OS !== "android" && (
            <Switch
              disabled={disableSwitch}
              onChange={() => updateList(item)}
              checked={checked}
              checkedIcon={false}
              uncheckedIcon={false}
              height={24}
              width={44}
              handleDiameter={20}
              offColor="#636A70"
              onColor="#007FAA"
            />
          )}
          {OS === "android" && (
            <Switch
              disabled={disableSwitch}
              onChange={() => updateList(item)}
              checked={checked}
              uncheckedIcon={false}
              checkedIcon={false}
              height={14}
              width={30}
              handleDiameter={20}
              onColor="#BCDFEE"
              onHandleColor="#0098CE"
              boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
            />
          )}
          <DragHandle />
        </div>
      </div>
      {hasGoal(item.name) && item.name !== "sleep" && (
        <div className="settings-item-content">
          <label className="m-0 p-0">
            {getGoalLabel(item.name, DataService.displayPreference)}
          </label>
          <input
            className="goal-metric"
            type="number"
            pattern="[0-9]*"
            value={goalValue}
            onKeyUp={(e) => {
              handleEnter(e);
            }}
            onChange={updateGoal}
          />
        </div>
      )}
      {hasGoal(item.name) && item.name === "sleep" && (
        <div className="settings-item-content">
          <label className="m-0 p-0">
            {getGoalLabel(item.name, DataService.displayPreference)}
          </label>
          <SleepGoalPicker goalValue={goalValue} updateGoal={updateGoal} />
        </div>
      )}
      {item.name === "heart_rate" && (
        <div className="hr-time-in-zone">
          {I18n.t("javascript.statistics.utility.time_in_zone_desc")}
        </div>
      )}
    </div>
  );
}
