import React, { useContext, useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Context } from "../Store";
import SettingsItem from "./SettingsItem";

const SortableList = SortableContainer(({ items, updateList, sortEnded }) => {

  return (
    <div className="sortable-container">
      {items.map((value, index) => (
        <SortableItem
          key={value.name}
          index={index}
          value={value}
          updateList={updateList}
          sortEnded={sortEnded}
        />
      ))}
    </div>
  );
});
const SortableItem = SortableElement(({ value, updateList, sortEnded }) => (
  <SettingsItem
    updateList={() => updateList(value)}
    checked={value.show}
    item={value}
    sortEnded={sortEnded}
  />
));

export default function DragList() {
  const [state, dispatch] = useContext(Context);
  const [sortEnded, setSortEnded] = useState(false);
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  let onSortEnd = ({ oldIndex, newIndex }) => {
    setSortEnded(true);
    const items = reorder(state.statsCards, oldIndex, newIndex);
    dispatch({ type: "set_stats_cards", payload: items });
    setSortEnded(false);
  };
  const updateList = (item) => {
    let newState = state.statsCards.map((a) => {
      let value = { ...a };
      if (a.name === item.name) {
        value.show = !value.show;
      }
      return value;
    });
    dispatch({ type: "set_stats_cards", payload: newState });
  };

  return (
    <SortableList
      items={state.statsCards}
      onSortEnd={({ newIndex, oldIndex }) => onSortEnd({ newIndex, oldIndex })}
      useDragHandle
      lockAxis={"y"}
      lockToContainerEdges={true}
      lockOffset={["20%", "100%"]}
      pressDelay={100}
      helperClass={"sortable-helper"}
      updateList={updateList}
      sortEnded={sortEnded}
    />
  );
}
