import React, { useState } from "react";
import { Collapse } from "react-collapse";
import I18n from "../Translation";
import { getDailyDistanceFormat, msToTime } from "../Utility";
import DataService from "../DataService";

export default function CollapseItem({
  iconName,
  title,
  isTimeWorkout,
  totalDistance,
  familyItems,
  totalTime,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const getName = (id) => {
    let workoutName = I18n.t("javascript.statistics.utility.workout");
    if (I18n.translations.en.javascript.statistics.workout_types[`wt_${id}`]) {
      workoutName = I18n.t(`javascript.statistics.workout_types.wt_${id}`);
    }
    return workoutName;
  };

  return (
    <div className="collapse-item" onClick={() => setIsOpen(!isOpen)}>
      <div
        className={`collapse-item-header ${
          familyItems.length === 1 && "extra-right"
        }`}
      >
        <div className="collapse-item-header-content">
          <div className="title">
            <div className="icon">
              <img
                className="workout-icon"
                src={`/static/${iconName}.svg`}
                alt="workout-icon"
                width="100%"
                height={18}
              />
            </div>
            <div className="text">
              {familyItems.length > 1
                ? I18n.t(`javascript.statistics.utility.${title}`)
                : getName(familyItems[0].workout_type_id)}
            </div>
          </div>
          <div className="total-distance">
            {!isTimeWorkout &&
              getDailyDistanceFormat(
                totalDistance,
                "other",
                DataService.displayPreference,
                true,
                totalDistance < 100 ? 2 : 1
              )}
            {isTimeWorkout && msToTime(totalTime * 1000, true)}
            {familyItems.length > 1 && (
              <div className={isOpen ? "expand-arrow workout-icon open" : "expand-arrow workout-icon"}>
                <img
                  src="/static/right.svg"
                  alt="expand"
                  height={12}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {familyItems.length > 1 && (
        <Collapse isOpened={isOpen}>
          <div className="collapse-item-panel">
            {familyItems && (
              <div className="family-items">
                {familyItems.map((obj, index) => {
                  let workoutName = getName(obj.workout_type_id);
                  // time workouts must have duration, distance workouts must have distance
                  if((isTimeWorkout && obj.duration_total_accum > 0) || (!isTimeWorkout && obj.distance_accum > 0))
                  return (
                    <div
                      key={index}
                      className="family-item"
                    >
                      <div>{workoutName}</div>
                      <div className="family-distance">
                        {!isTimeWorkout &&
                          getDailyDistanceFormat(
                            obj.distance_accum,
                            "other",
                            DataService.displayPreference,
                            true,
                            totalDistance < 100 ? 2 : 1
                          )}
                        {isTimeWorkout &&
                          msToTime(obj.duration_total_accum * 1000, true)}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </Collapse>
      )}
    </div>
  );
}
