import React from "react";
import DragList from "./DragList";
import { useSwipeable } from "react-swipeable";
import { useHistory } from "react-router-dom";

export default function SettingsPage() {
  let history = useHistory();
  const handlers = useSwipeable({
    onSwipedRight: () => {
      history.push("/");
    },
  });

  return (
    <div className="app page settings-container">
      <div className="content">
        <div className="content-scroll">
          <div {...handlers}>
            <DragList />
          </div>
        </div>
      </div>
    </div>
  );
}
