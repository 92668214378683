import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import DataService from "./DataService";
import { Context } from "./Store";
import { getOS } from "./Utility";
import I18n from "./Translation";
import InfoModal from "./statistics/InfoModal";

export default function Navigation() {
  let OS = getOS() || "ios";
  const [state, dispatch] = useContext(Context);
  const [showAnimate, setShowAnimate] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  let history = useHistory();
  let page = history.location.pathname;
  let title =
    page === "/"
      ? I18n.t("javascript.statistics.navigation.statistics")
      : I18n.t("javascript.statistics.navigation.edit_cards");

  //run CloudService method when user navigates back
  const updateUserSummaryTargets = async () => {
    await DataService.updateUserSummaryTarget({
      ...state.targetGoals,
      card_settings: state.statsCards,
    });
  };
  let slider = document.getElementById("slider-nav");
  const slide = () => {
    if (slider) {
      slider.setAttribute("class", "slide-in");
    }
    setTimeout(() => {
      setShowAnimate(false);
    }, 400);
  };

  const Android = () => {
    return (
      <div className={`navigation ${showAnimate ? "showAnimate" : ""}`}>
        {page === "/" && (
          <div className="wrapper slide-in" id="slider-nav">
            <div className="d-flex">
              <div className="statistics-title">
                <h1>{title}</h1>
              </div>
              <div onClick={() => setModalOpen(true)} className="info-help">
                <img
                  src="/static/info_circle.svg"
                  alt="previous"
                  height={16}
                />
              </div>
            </div>
            <div
              onClick={() => {
                setShowAnimate(true);
                history.push("/settings");
                slide();
              }}
            >
              <img
                src="/static/filter.svg"
                alt="settings"
                height={19}
              />
            </div>
          </div>
        )}
        {page === "/settings" && (
          <div
            className="wrapper-edit slide-in-settings"
            id="slider-nav-settings"
          >
            <div
              className="back"
              onClick={() => {
                setShowAnimate(true);
                updateUserSummaryTargets();
                slide();
                history.push("/");
              }}
            >
              <img
                className="workout-icon"
                src="/static/android_left.svg"
                alt="back"
                height={16}
              />
            </div>
            <div>
              <h1>{title}</h1>
            </div>
          </div>
        )}
      </div>
    );
  };

  const Apple = () => {
    return (
      <div className="navigation">
        <div className="left">
          {page === "/settings" && (
            <div
              className="back"
              onClick={() => {
                updateUserSummaryTargets();
                history.push("/");
              }}
            >
              <img
                src="/static/ios_left.svg"
                alt="back"
                height={19}
              />
              {I18n.t("javascript.statistics.navigation.back")}
            </div>
          )}
          {page === "/" && (
            <div onClick={() => setModalOpen(true)} className="back info-help">
              <img
                src="/static/info_circle.svg"
                alt="previous"
                height={16}
              />
            </div>
          )}
        </div>
        <div className="center">
          <h1>{title}</h1>
        </div>
        <div className="right">
          {page === "/" && (
            <div
              className="click"
              onClick={() => {
                history.push("/settings");
              }}
            >
              <img
                src="/static/filter.svg"
                alt="settings"
                height={20}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {OS === "android" && <Android />}
      {OS !== "android" && <Apple />}
      <InfoModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        title={I18n.t("javascript.statistics.utility.help")}
      >
        <div className="mt-1 mb-1 p-4">
          <div>{I18n.t("javascript.statistics.utility.help_text_first")}</div>
          <br />
          <div>{I18n.t("javascript.statistics.utility.help_text_second")}</div>
          <br />
          <div>{I18n.t("javascript.statistics.utility.help_text_third")}</div>
        </div>
      </InfoModal>
    </>
  );
}
