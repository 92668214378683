import React, { useState } from "react";
import { currentDayCode, dayCodeToDate } from "../../Utility";
import HrvChart from "../HrvChart";
import I18n from "../../Translation";
import InfoModal from "../InfoModal";

export default function HrvCard() {
  const [chartCount, setChartCount] = useState(7);
  const [showHrv, setShowHrv] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(0);
  const [currentItemDayCode, setCurrentItemDayCode] = useState(currentDayCode());

  return (
    <div className="hrv-card card-body">
      {!showHrv && (
        <div className="p-4">
            {I18n.t("javascript.statistics.statistics_page.empty_hrv_description")}
        </div>
      )}
      {showHrv && (
        <>
          <div className="hrv-info-link">
            <div className="link-text" onClick={() => setModalOpen(true)}>
              {I18n.t("javascript.statistics.utility.more_info")}
            </div>
          </div>
          <div className="card-top-info">
            <div className="date">
              {dayCodeToDate(currentItemDayCode, true)}
            </div>
            <div className="d-flex justify-content-center">
              <div className="hrv-reading">{currentItem}</div>
            </div>
            <div className="arrow-line">
              <div className="bottom-arrow" />
            </div>
          </div>
          <HrvChart
            chartCount={chartCount}
            currentItem={currentItem}
            setCurrentItem={setCurrentItem}
            currentItemDayCode={currentItemDayCode}
            setCurrentItemDayCode={setCurrentItemDayCode}
          />
          <div className="card-header-toggle">
            <div className="tab-wrapper">
              <div
                onClick={() => setChartCount(7)}
                className={
                  chartCount === 7
                    ? "card-header-toggle-button active"
                    : "card-header-toggle-button"
                }
              >
                {I18n.t("javascript.statistics.utility.days", {
                  days: "7",
                })}
              </div>
              <div
                onClick={() => setChartCount(14)}
                className={
                  chartCount === 14
                    ? "card-header-toggle-button active"
                    : "card-header-toggle-button"
                }
              >
                {I18n.t("javascript.statistics.utility.days", {
                  days: "14",
                })}
              </div>
              <div
                onClick={() => setChartCount(30)}
                className={
                  chartCount === 30
                    ? "card-header-toggle-button active"
                    : "card-header-toggle-button"
                }
              >
                {I18n.t("javascript.statistics.utility.days", {
                  days: "30",
                })}
              </div>
            </div>
          </div>
          <div className="hrv-info-row">
            <div>{I18n.t("javascript.statistics.statistics_page.average")}</div>
            <div>
              {I18n.t("javascript.statistics.statistics_page.ms", {
                ms: "55",
              })}
            </div>
          </div>
          <div className="hrv-info-row">
            <div>{I18n.t("javascript.statistics.statistics_page.high")}</div>
            <div>
              {I18n.t("javascript.statistics.statistics_page.ms", {
                ms: "55",
              })}
            </div>
          </div>
          <div className="hrv-info-row">
            <div>{I18n.t("javascript.statistics.statistics_page.low")}</div>
            <div>
              {I18n.t("javascript.statistics.statistics_page.ms", {
                ms: "55",
              })}
            </div>
          </div>
        </>
      )}
      <InfoModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        title={I18n.t("javascript.statistics.statistics_page.hrv_information")}
      >
        <div className="p-4">
          {I18n.t("javascript.statistics.statistics_page.hrv_description")}
        </div>
      </InfoModal>
    </div>
  );
}
