const SLEEP_COLORS = {
  unspecified: {
    color: "#C4C4C4",
    displayName: 'javascript.statistics.statistics_page.uncategorized',
    description: 'javascript.statistics.statistics_page.uncategorized_description'
  },
  deep: {
    color: "#00375A",
    displayName: 'javascript.statistics.statistics_page.deep_sleep',
    description: 'javascript.statistics.statistics_page.deep_sleep_description'
  },
  light: {
    color: "#0098CE",
    displayName: 'javascript.statistics.statistics_page.light_sleep',
    description: 'javascript.statistics.statistics_page.light_sleep_description'
  },
  rem: {
    color: "#006293",
    displayName: 'javascript.statistics.statistics_page.rem',
    description: 'javascript.statistics.statistics_page.rem_description'
  },
  awake: {
    color: "#6ECDF0",
    displayName: 'javascript.statistics.statistics_page.awake',
    description: 'javascript.statistics.statistics_page.awake'
  },
};

export default SLEEP_COLORS;
