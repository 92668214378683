import React, { useEffect, useState } from "react";
import DataService from "../../DataService";
import { find, indexOf, sumBy, groupBy } from "lodash-es";
import PubSub from "pubsub-js";
import CollapseItem from "../CollapseItem";
import { getWorkoutFamilyIconName, getWorkoutIconName } from "../../Utility";
import I18n from "../../Translation";

export default function OdometerCard() {
  const [workoutTypeSummaries, setWorkoutTypeSummaries] = useState(
    DataService.workoutTypeSummaries
  );
  let grouped = groupBy(workoutTypeSummaries, "family");

  if (grouped && grouped["cycling"]) {
    // Look for cycling grouping in workoutTypeSummaries
    // If there is workouts of type 12 (BIKING_INDOOR) and of type 61 (BIKING_INDOOR_TRAINER), combine them
    // type 61 was created for KICKR, to help auto set a workout profile when it is paired.  It is trash duplicate of 12.
    if (
      find(grouped["cycling"], { workout_type_id: 12 }) &&
      find(grouped["cycling"], { workout_type_id: 61 })
    ) {
      let indoorKeep = find(grouped["cycling"], { workout_type_id: 12 });
      let indoorKeepIndex = indexOf(grouped["cycling"], indoorKeep);
      let indoorDestroy = find(grouped["cycling"], { workout_type_id: 61 });
      let indoorDestroyIndex = indexOf(grouped["cycling"], indoorDestroy);

      // take the distance and duration properties from wt_61 and add them to wt_12
      grouped["cycling"][indoorKeepIndex]["distance_accum"] +=
        grouped["cycling"][indoorDestroyIndex]["distance_accum"];
      grouped["cycling"][indoorKeepIndex]["duration_total_accum"] +=
        grouped["cycling"][indoorDestroyIndex]["duration_total_accum"];

      // set wt_61 distance to 0.  It is not a time workout, so with a distance of 0, it will not be displayed.
      grouped["cycling"][indoorDestroyIndex]["distance_accum"] = 0;
    }
  }

  useEffect(() => {
    let token = PubSub.subscribe(
      "workout_type_summaries_changed",
      function (msg, data) {
        setWorkoutTypeSummaries(DataService.workoutTypeSummaries);
      }
    );
    return function cleanup() {
      console.log(`StepsCode[unsubscribe] token: ${token}`);
      PubSub.unsubscribe(token);
    };
  }, []);

  if (workoutTypeSummaries.length === 0) {
    return (
      <div className="odometer-card card-body">
        <div className="p-4 empty-description">
          {I18n.t(
            "javascript.statistics.statistics_page.empty_odometer_description"
          )}
        </div>
      </div>
    );
  }
  return (
    <div className="odometer-card card-body">
      {Object.entries(grouped).map(([key, value], index) => {
        let totalDistance = sumBy(value, "distance_accum");
        let totalTime = sumBy(value, "duration_total_accum");
        let isTimeWorkout = value[0].is_time_workout;
        let iconName =
          value.length > 1
            ? getWorkoutFamilyIconName(value[0].family)
            : getWorkoutIconName(value[0].workout_type_id);
        if (isTimeWorkout || totalDistance > 0) {
          return (
            <CollapseItem
              key={index}
              iconName={iconName}
              title={key}
              isTimeWorkout={isTimeWorkout}
              totalDistance={totalDistance}
              familyItems={value}
              totalTime={totalTime}
            />
          );
        }
      })}
    </div>
  );
}
