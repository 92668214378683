import React, { useState, useContext, useEffect, useRef } from "react";
import DailySleep from "../DailySleep";
import WeeklySleep from "../WeeklySleep";
import I18n from "../../Translation";
import DataService from "../../DataService";
import { Context } from "../../Store";
import PubSub from "pubsub-js";
import {getWeekRangeForDayCode, prefersDarkMode} from "../../Utility";
import { range } from "lodash-es";

export default function SleepCard() {
  const [state, dispatch] = useContext(Context);
  const [isDark, setIsDark] = useState(prefersDarkMode());
  const [cardState, setCardState] = useState("daily");
  let emptySleep = {
    duration: 0,
    stage_0: 1,
    stage_1: 0,
    stage_2: 0,
    stage_3: 0,
    stage_4: 0,
    start_time: "",
    end_time: "",
  };
  let indexRef = useRef(state.activeDailySleepIndex);
  const [currentSleep, setCurrentSleep] = useState(
    DataService.sleepSummaries.find(
      (item) => item.day_code === state.activeDailySleepIndex
    ) || emptySleep
  );
  const getWeeklySleep = () => {
    let currentWeekRange = getWeekRangeForDayCode(state.activeDailySleepIndex);
    let weeklySleep = [];
    let days = range([currentWeekRange.first], currentWeekRange.last + 1, [1]);
    days.forEach((dayCode) => {
      let summary = DataService.sleepSummaries.find(
        (item) => item.day_code === dayCode
      );
      if (summary) {
        weeklySleep.push(summary);
      } else {
        weeklySleep.push({ ...emptySleep, dayCode: dayCode });
      }
    });
    return weeklySleep;
  };
  const [weeklySleep, setWeeklySleep] = useState(getWeeklySleep());

  useEffect(
    (emptySleep) => {
      indexRef.current = state.activeDailySleepIndex;
      let value = DataService.sleepSummaries.find(
        (item) => item.day_code === state.activeDailySleepIndex
      );
      if (value) {
        setCurrentSleep(value);
      } else {
        setCurrentSleep(emptySleep);
      }
      setWeeklySleep(getWeeklySleep());
    },
    [state.activeDailySleepIndex]
  );

  useEffect(() => {
    let token = PubSub.subscribe("sleepsummarychanged", function (msg, data) {
      if (indexRef.current === data.dayCode) {
        let value = DataService.sleepSummaries.find(
          (item) => item.day_code === data.dayCode
        );
        setCurrentSleep(value);
      }
      setWeeklySleep(getWeeklySleep());
    });
    return function cleanup() {
      console.log(`SleepCode[unsubscribe] token: ${token}`);
      PubSub.unsubscribe(token);
    };
  }, []);

  function setLight(e) {
    if (e.matches) {
      setIsDark(false);
    }
  }
  function setDark(e) {
    if (e.matches) {
      setIsDark(true);
    }
  }

  useEffect(() => {
    // use media query listener and isDark to trigger useEffect above, which will re-draw the chart
    const darkQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const lightQuery = window.matchMedia("(prefers-color-scheme: light)");

    if (darkQuery.addEventListener) {
      darkQuery.addEventListener("change", setDark);
      lightQuery.addEventListener("change", setLight);
    } else {
      darkQuery.addListener(setDark);
      lightQuery.addListener(setLight);
    }

    return () => {
      if (darkQuery.removeEventListener) {
        lightQuery.removeEventListener("change", setLight);
        darkQuery.removeEventListener("change", setDark);
      } else {
        lightQuery.removeListener(setLight);
        darkQuery.removeListener(setDark);
      }
    };
  }, []);

  return (
    <div className="sleep-card card-body">
      <div className="card-header-toggle">
        <div className="tab-wrapper">
          <div
            onClick={() => setCardState("daily")}
            className={
              cardState === "daily"
                ? "card-header-toggle-button active"
                : "card-header-toggle-button"
            }
          >
            {I18n.t("javascript.statistics.utility.daily")}
          </div>
          <div
            onClick={() => setCardState("weekly")}
            className={
              cardState === "weekly"
                ? "card-header-toggle-button active"
                : "card-header-toggle-button"
            }
          >
            {I18n.t("javascript.statistics.utility.weekly")}
          </div>
        </div>
      </div>
      {cardState === "daily" && (
        <DailySleep currentSleep={currentSleep} isDark={isDark} />
      )}
      {cardState === "weekly" && <WeeklySleep weeklySleep={weeklySleep} isDark={isDark} />}
    </div>
  );
}
