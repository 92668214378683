import React, { Component } from "react";
import Picker from "./Picker";
import $ from "jquery";
import I18n from "../Translation";
import { minutesToTime } from "../Utility";

function generateNumberArray(begin, end) {
  let array = [];
  for (let i = begin; i <= end; i++) {
    array.push({
      value: (i < 10 ? "0" : "") + i,
      label: (i < 10 ? "0" : "") + i,
    });
  }
  return array;
}

export default class SleepGoalPicker extends Component {
  constructor(props) {
    super(props);
    let time = minutesToTime(this.props.goalValue, "with_zero");
    this.state = {
      time: minutesToTime(this.props.goalValue, "with_zero"),
      isPickerShow: false,
      valueGroups: { hour: time.split(":")[0], minute: time.split(":")[1] },
      optionGroups: {
        hour: generateNumberArray(2, 16),
        minute: [
          { value: "00", label: "00" },
          { value: "15", label: "15" },
          { value: "30", label: "30" },
          { value: "45", label: "45" },
        ],
      },
    };
  }

  handleChange = (name, value) => {
    this.setState(({ valueGroups }) => ({
      valueGroups: {
        ...valueGroups,
        [name]: value,
      },
    }));
  };

  handleCancel = () => {
    this.setState(({ time, valueGroups }) => ({
      valueGroups: {
        ...valueGroups,
        hour: time.split(":")[0],
        minute: time.split(":")[1],
      },
    }));
  };

  handleConfirm = () => {
    let totalMinutes =
      parseInt(this.state.valueGroups.hour) * 60 +
      parseInt(this.state.valueGroups.minute);
    this.props.updateGoal(totalMinutes);
  };

  togglePicker = () => {
    this.setState(({ isPickerShow }) => ({
      isPickerShow: !isPickerShow,
    }));
    // block background scrolling when the picker is open
    if (!this.state.isPickerShow) {
      $(".content-scroll").css("overflow", "hidden");
    }
    if (this.state.isPickerShow) {
      $(".content-scroll").css("overflow", "scroll");
    }
  };

  render() {
    const { isPickerShow, optionGroups, valueGroups } = this.state;
    const maskStyle = {
      display: isPickerShow ? "block" : "none",
    };
    const pickerModalClass = `picker-modal${
      isPickerShow ? " picker-modal-toggle" : " d-none"
    }`;

    return (
      <div className="picker-container">
        <div>
          <div>
            <div>
              <input
                className="goal-metric"
                type="text"
                value={valueGroups.hour + ":" + valueGroups.minute}
                readOnly
                onClick={this.togglePicker}
              />
            </div>
          </div>
        </div>
        <div className="picker-modal-container">
          <div
            className="picker-modal-mask"
            style={maskStyle}
            onClick={() => {
              this.handleCancel();
              this.togglePicker();
            }}
          />
          <div className={pickerModalClass}>
            <div className="picker-top-text">
              <div className="title">
                {I18n.t("javascript.statistics.statistics_page.sleep_goal")}
              </div>
            </div>
            <div className="column-labels">
              <div className="pr-4">
                {I18n.t("javascript.statistics.utility.hours")}
              </div>
              <div className="pl-4">
                {I18n.t("javascript.statistics.utility.minutes")}
              </div>
            </div>
            <Picker
              optionGroups={optionGroups}
              valueGroups={valueGroups}
              onChange={this.handleChange}
            />
            <div className="picker-actions">
              <div
                className="mr-4 picker-cancel"
                onClick={() => {
                  this.handleCancel();
                  this.togglePicker();
                }}
              >
                {I18n.t("javascript.statistics.utility.cancel")}
              </div>
              <button
                className="picker-confirm"
                onClick={() => {
                  this.handleConfirm();
                  this.togglePicker();
                }}
              >
                {I18n.t("javascript.statistics.utility.ok")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
