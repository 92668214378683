import React, { Component } from "react";
import Picker from "./Picker";
import I18n from "../Translation";
import { dayCodeToDate, validateSleepEdit } from "../Utility";

const generateNumberArray = (begin, end, isHours) => {
  let array = [];
  for (let i = begin; i <= end; i++) {
    if (i === 0 && isHours) {
      // 00 in utc is 12:00am
      array.push({
        value: i,
        label: "12",
      });
      continue;
    }
    array.push({
      value: i,
      label: (i < 10 ? "0" : "") + i,
    });
  }
  return array;
};

const displayNumber = (num) => {
  if (num < 10) {
    return `0${num}`;
  }
  return `${num}`;
};

const dateInputDisplay = (isStartTime, day, valueGroups, optionGroups) => {
  const dayDisplay = isStartTime ? `[${day}] ` : "";
  // use the OptionGroups to display hours because 0am doesn't exist
  const hourDisplay = optionGroups.hour[valueGroups.hour]["label"];
  const minutesDisplay = `${displayNumber(valueGroups.minute)}${valueGroups.M}`;
  return `${dayDisplay}${hourDisplay}:${minutesDisplay}`;
};

const generateDateButtonString = (date) => {
  const dayString = date.toLocaleDateString(I18n.currentLocale(), {weekday: "short"})
  const dayMonth = date.toLocaleDateString(I18n.currentLocale(), {month: "numeric", day: "numeric"})
  return `${dayString} ${dayMonth}`
}

const get24Hour = (hour, M) => {
  if (hour === 12) {
    if (M === "am") {
      return 0;
    }
    return hour;
  } else {
    if (M === "pm") {
      return 12 + hour;
    }
    return hour;
  }
};

const addTimeToDate = (date, valueGroups) => {
  let hoursToAdd = get24Hour(valueGroups.hour, valueGroups.M);
  date.setHours(hoursToAdd);
  date.setMinutes(valueGroups.minute);
  return date;
};

export default class SleepTimePicker extends Component {
  constructor(props) {
    super(props);
    let date = this.props.isStartTime
      ? new Date(`${this.props.session.start_time}`)
      : new Date(`${this.props.session.end_time}`);
    date.setSeconds(0); // remove seconds from the date.  they are redundant and will break a date equality check
    let hours = date.getHours();
    let M = "";
    if (hours < 12) {
      M = "am";
    }
    if (hours >= 12) {
      if (hours !== 12) {
        hours = hours - 12;
      }
      M = "pm";
    }
    // add the date, hours in 12 hour format, and M to the state so we can reset the input on cancel
    this.state = {
      date: date,
      hours: hours,
      M: M,
      isFirstDay: date < dayCodeToDate(this.props.session.day_code),
      isPickerShow: false,
      valueGroups: { hour: hours, minute: date.getMinutes(), M: M },
      error: false,
      errorText: "",
      optionGroups: {
        hour: generateNumberArray(0, 12, true),
        minute: generateNumberArray(0, 59),
        M: [
          { value: "am", label: "am" },
          { value: "pm", label: "pm" },
        ],
      },
    };
  }

  handleChange = (name, value) => {
    this.setState(({ valueGroups }) => ({
      valueGroups: {
        ...valueGroups,
        [name]: value,
      },
    }));
  };

  handleCancel = (hours, date, M) => {
    this.setState(({ valueGroups }) => ({
      valueGroups: {
        ...valueGroups,
        hour: hours,
        minute: date.getMinutes(),
        M: M,
      },
      error: false,
      errorText: "",
    }));
  };

  handleConfirm = async () => {
    const { valueGroups, isFirstDay, date } = this.state;
    const { session, isStartTime } = this.props;
    // generate a date
    let editDate = isFirstDay
      ? dayCodeToDate(session.day_code - 1)
      : dayCodeToDate(session.day_code);
    // adjust the date based on user input
    editDate = addTimeToDate(editDate, valueGroups);
    // close modal if the user made no change
    if (date.getTime() === editDate.getTime()) {
      this.setState(() => ({
        error: false,
      }));
      this.togglePicker();
    }
    let hasError = await validateSleepEdit(editDate, session, isStartTime);
    if (hasError) {
      this.setState(() => ({
        error: hasError,
        errorText: hasError,
      }));
    }
    if (!hasError) {
      this.setState(() => ({
        error: false,
        errorText: "",
      }));
      this.props.setTimeForUpdate(editDate.toISOString())
      this.togglePicker();
    }
  };

  togglePicker = () => {
    this.setState(({ isPickerShow }) => ({
      isPickerShow: !isPickerShow,
    }));
  };

  render() {
    const {
      isPickerShow,
      optionGroups,
      valueGroups,
      date,
      isFirstDay,
      hours,
      M,
      error,
      errorText,
    } = this.state;
    const maskStyle = {
      display: isPickerShow ? "block" : "none",
    };
    const pickerModalClass = `picker-modal${
      isPickerShow ? " picker-modal-toggle" : " d-none"
    }`;

    return (
      <div className="picker-container">
        <div>
          <div>
            <div>
              <input
                className="goal-metric"
                type="text"
                value={dateInputDisplay(
                  this.props.isStartTime,
                  // if the user has selected "day 2" in the picker, the weekday abbreviation should match the session day_code
                  isFirstDay
                    ? date.toLocaleDateString(I18n.currentLocale(), {
                        weekday: "short",
                      })
                    : dayCodeToDate(
                        this.props.session.day_code
                      ).toLocaleDateString(I18n.currentLocale(), {
                        weekday: "short",
                      }),
                  valueGroups,
                  optionGroups
                )}
                readOnly
                onClick={this.togglePicker}
              />
            </div>
          </div>
        </div>
        <div className="picker-modal-container">
          <div
            className="picker-modal-mask"
            style={maskStyle}
            onClick={() => {
              this.handleCancel(hours, date, M);
              this.togglePicker();
            }}
          >
            {error && (
              <div className="picker-error">
                <div className="mr-2">
                  <img
                    src="/static/error.svg"
                    alt="settings"
                    height={19}
                  />
                </div>
                <div>{errorText}</div>
              </div>
            )}
          </div>

          <div className={pickerModalClass}>
            <div className="picker-top-text">
              <div className="title">
                {this.props.isStartTime
                  ? I18n.t("javascript.statistics.statistics_page.time_sleep")
                  : I18n.t("javascript.statistics.statistics_page.time_wake")}
              </div>
            </div>
            <div className="picker-days">
              <button
                onClick={() => {
                  if (!isFirstDay) {
                    this.setState(() => ({
                      isFirstDay: true,
                    }));
                  }
                }}
                className={`picker-days-button mr-3 ${isFirstDay && "active"}`}
              >
                {generateDateButtonString(dayCodeToDate(this.props.session.day_code - 1))}

              </button>
              <button
                onClick={() => {
                  if (isFirstDay) {
                    this.setState(() => ({
                      isFirstDay: false,
                    }));
                  }
                }}
                className={`picker-days-button ${!isFirstDay && "active"}`}
              >
                {generateDateButtonString(dayCodeToDate(this.props.session.day_code))}
              </button>
            </div>
            <div className="column-labels">
              <div>
                {I18n.t("javascript.statistics.utility.hours")}
              </div>
              <div>
                {I18n.t("javascript.statistics.utility.minutes")}
              </div>
              <div></div>
            </div>
            <Picker
              optionGroups={optionGroups}
              valueGroups={valueGroups}
              onChange={this.handleChange}
            />
            <div className="picker-actions">
              <div
                className="mr-4 picker-cancel"
                onClick={() => {
                  this.handleCancel(hours, date, M);
                  this.togglePicker();
                }}
              >
                {I18n.t("javascript.statistics.utility.cancel")}
              </div>
              <button
                className="picker-confirm"
                onClick={() => {
                  this.handleConfirm();
                }}
              >
                {I18n.t("javascript.statistics.utility.ok")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
