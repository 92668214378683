import React from "react";
import I18n from "../Translation";
import { minutesToTime } from "../Utility";

export default function DonutContent({ sleepGoal, duration, stage4 }) {
  const minutesSlept = Math.floor((duration - stage4) / 60);
  const metGoal = sleepGoal > 0 && minutesSlept >= sleepGoal;

  if (duration === 0) {
    return (
      <div>
        {I18n.t(
          "javascript.statistics.statistics_page.empty_daily_sleep_donut"
        )}
      </div>
    );
  }

  return (
    <>
      {metGoal && (
        <>
          <div className="goal-icon">
            <img
              className="workout-icon"
              src="/static/check_circle.svg"
              alt="circle"
              height={18}
            />
          </div>
          <div className="goal-text">
            {I18n.t("javascript.statistics.statistics_page.goal_achieved")}
          </div>
        </>
      )}
      <div className="goal-value">
        {minutesToTime(minutesSlept, "medium")}
      </div>
      <div className={`goal-text ${metGoal ? 'mb-2' : ''}`}>
          {minutesToTime(Math.floor(duration / 60)) + ' ' + I18n.t("javascript.statistics.statistics_page.total_duration")}
      </div>
    </>
  );
}
