import styled, { css } from "styled-components";

const createAnimationStyles = ({
  keyframes,
  delay,
  duration,
  fill,
  origin,
  onTop,
}) => css`
  animation-name: ${keyframes};
  animation-delay: ${delay};
  animation-duration: ${duration}ms;
  animation-timing-function: 'cubic-bezier(.42,0,.58,1)',
  animation-fill-mode: ${fill};
  transform-origin: ${origin || "50% 50%"};
  ${
    onTop &&
    css`
      z-index: 1;
    `
  }
`;

const stateMap = {
  entering: ({ enterAnimation }) => {
    return css`
      ${createAnimationStyles(enterAnimation)};
    `;
  },
  exiting: ({ exitAnimation }) => {
    return css`
      ${createAnimationStyles(exitAnimation)};
    `;
  },
};

export const PageTransitionWrapper = styled.div`
  ${({ state }) => stateMap[state]};
`;
