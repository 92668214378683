import React from "react";

export default function ClipLoader() {
  return (
    <div className="clip-loader">
      <svg className="spinner" viewBox="0 0 50 50">
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="4"
        ></circle>
      </svg>
    </div>
  );
}
