import axios from "axios";
import { max } from "lodash-es";

const CloudService = {
  initialLookup: async function(locale) {
    let response = await axios.post(`/api/v1/statistics_component_lookups?locale=${locale ?? "en"}`, { statistics_component_lookup: { num_days: 14 } });
    console.log(
        `CLOUD_SERVICE[initialLookup] received ${JSON.stringify(
            response.data
        )}`
    );
    return response.data;
  },
  updateUserSummaryTarget: async function(data) {
    let response = await axios.put("/api/v1/user_summary_targets", data);
    console.log(
      `CLOUD_SERVICE[updateUserSummaryTarget] received ${JSON.stringify(
        response.data
      )}`
    );
    return response.data;
  },
  // default the limit to 14 unless a component data function requires more days
  getDailySummariesBeforeDate: async function(beforeDate, count = 14) {
    console.log(
      `CLOUD_SERVICE[getDailySummariesBeforeDate] before: ${beforeDate}`
    );
    let response = await axios.get(
      `/api/v1/user_daily_summaries?before=${beforeDate}&limit=${max([count, 14])}`
    );
    console.log(
      `CLOUD_SERVICE[getDailySummariesBeforeDate] received ${response.data.length} records`
    );
    return response.data;
  },
  getSleepSummariesBeforeDate: async function(beforeDate, count = 14){
    console.log(
        `CLOUD_SERVICE[getSleepSummariesBeforeDate] before: ${beforeDate}`
    );
    let response = await axios.get(
        `/api/v1/sleep_summaries?before=${beforeDate}&limit=${max([count, 14])}`
    )
    console.log(
        `CLOUD_SERVICE[getDailySummariesBeforeDate] received ${response.data.length} records`
    );
    return response.data;
  },
  getDailySummariesAfterDate: async function(afterDate, count = 14) {
    console.log(
        `CLOUD_SERVICE[getDailySummariesAfterDate] after: ${afterDate}`
    );
    let response = await axios.get(`/api/v1/user_daily_summaries?after=${afterDate}&limit=${count}`);
    return response.data;
  },
  getSleepSummariesAfterDate: async function(afterDate, count = 14) {
    console.log(
        `CLOUD_SERVICE[getSleepSummariesAfterDate] after: ${afterDate}`
    );
    let response = await axios.get(`/api/v1/sleep_summaries?after=${afterDate}&limit=${count}`);
    return response.data;
  },
  getSleepSummaries: async function(afterDate, beforeDate) {
    let response = await axios.get(`/api/v1/sleep_summaries?after=${afterDate}&before=${beforeDate}`);
    return response.data;
  },
  getDailySummary: async function(id) {
    let response = await axios.get(`/api/v1/user_daily_summaries/${id}`);
    return response.data;
  },
  getSleepSummary: async function(id) {
    let response = await axios.get(`/api/v1/sleep_summaries/${id}`);
    return response.data;
  },
  editSleepSummary: async function(id, rating){
    console.log(
        `CLOUD_SERVICE[editSleepSummary] SleepSummary: ${id} rating: ${rating}`
    );
    return await axios.put(`/api/v1/sleep_summaries/${id}`, {"sleep_summary": {"rating": rating}});
  },
  createSleepSessionEdit: async function(sleep_session_id, start_time, end_time) {
    console.log(
        `CLOUD_SERVICE[createSleepSessionEdit] SleepSession: ${sleep_session_id}`
    );
    return await axios.post(`/api/v1/sleep_sessions/${sleep_session_id}/sleep_session_edits`, {"sleep_session_edit": {"start_time": start_time, "end_time": end_time}})
  },
  getWorkoutTypeSummaries: async function() {
    let response = await axios.get("/api/v1/workout_type_summaries");
    console.log(
      `CLOUD_SERVICE[getWorkoutTypeSummaries] received ${response.data.length} records`
    );
    return response.data;
  },
};

export default CloudService;
