import React, { useContext, useEffect, useState } from "react";
import ClipLoader from "./loaders/ClipLoader";
import $ from "jquery";
import {
  getTooltipCoordinates,
  minutesToTime,
} from "../Utility";
import GoogleContext from "../GoogleContext";
import I18n from "../Translation";
import { Context } from "../Store";
import DonutContent from "./DonutContent";
import SLEEP_COLORS from "./SleepColors";

export default function DailySleepChart({ currentSleep, loading, isDark }) {
  const { google } = useContext(GoogleContext);
  const [state, dispatch] = useContext(Context);
  const [chart, setChart] = useState(null);
  const [data, setData] = useState(null);
  const [goalAchieved, setGoalAchieved] = useState(false);
  let sleepGoal = state.targetGoals.daily_sleep;

  useEffect(() => {
    if (google) {
      // Create the data table.
      const data = new google.visualization.DataTable();
      data.addColumn("string", "Type");
      data.addColumn("number", "Minutes");
      data.addColumn({ type: "string", role: "tooltip", p: { html: true } });
      if (currentSleep && currentSleep.duration > 0) {
        data.addRows([
          [
            I18n.t("javascript.statistics.statistics_page.uncategorized"),
            currentSleep.stage_0,
            '<div class="daily-sleep-tooltip">' +
              I18n.t("javascript.statistics.statistics_page.uncategorized") +
              "<br /><p>" +
              minutesToTime(currentSleep.stage_0 / 60) +
              "</p></div>",
          ],
          [
            I18n.t("javascript.statistics.statistics_page.deep_sleep"),
            currentSleep.stage_1,
            '<div class="daily-sleep-tooltip">' +
              I18n.t("javascript.statistics.statistics_page.deep_sleep") +
              "<br /><p>" +
              minutesToTime(currentSleep.stage_1 / 60) +
              "</p></div>",
          ],
          [
            I18n.t("javascript.statistics.statistics_page.rem"),
            currentSleep.stage_3,
            '<div class="daily-sleep-tooltip">' +
              I18n.t("javascript.statistics.statistics_page.rem") +
              "<br /><p>" +
              minutesToTime(currentSleep.stage_3 / 60) +
              "</p></div>",
          ],
          [
            I18n.t("javascript.statistics.statistics_page.light_sleep"),
            currentSleep.stage_2,
            '<div class="daily-sleep-tooltip">' +
            I18n.t("javascript.statistics.statistics_page.light_sleep") +
            "<br /><p>" +
            minutesToTime(currentSleep.stage_2 / 60) +
            "</p></div>",
          ],
        ]);
      } else {
        data.addRows([
          ["", 1, null],
          ["", 0, null],
          ["", 0, null],
          ["", 0, null],
          ["", 0, null],
        ]);
      }

      // save the data to the state so it can be used in the select handler
      setData(data);

      // Set chart options
      let options = {
        pieHole: 0.62,
        chartArea: { width: "100%", height: "91%" },
        height: 270,
        legend: "none",
        pieSliceBorderColor: "transparent",
        pieResidueSliceColor: "transparent",
        backgroundColor: isDark ? "#1e1e1e" : "#ffffff",
        slices: [
          { color: SLEEP_COLORS.unspecified.color },
          { color: SLEEP_COLORS.deep.color },
          { color: SLEEP_COLORS.rem.color },
          { color: SLEEP_COLORS.light.color },
          { color: SLEEP_COLORS.awake.color },
        ],
        tooltip: { isHtml: true, ignoreBounds: false, trigger: "selection" },
        pieSliceText: "none",
        enableInteractivity: !!currentSleep,
      };

      // Instantiate and draw the chart
      const newChart = new google.visualization.PieChart(
        document.getElementById("daily-sleep-chart")
      );
      newChart.draw(data, options);
      setChart(newChart);
    }
  }, [google, currentSleep, isDark]);

  if (google && chart && currentSleep) {
    const selectHandler = () => {
      // access the google chart api
      let cli = chart.getChartLayoutInterface();
      let selection = chart.getSelection();
      // if a current piece of pie is selected
      if (selection[0]) {
        let selectedSlice = selection[0].row;
        // position our tooltip in the closest corner to the selected section
        let sliceBoundingBox = cli.getBoundingBox(`slice#${selectedSlice}`);
        let coordinates = getTooltipCoordinates(sliceBoundingBox);
        $(".google-visualization-tooltip").css({
          left: `${coordinates.newLeft}px`,
          top: `${coordinates.newTop}px`,
        });
      }
    };
    // use to google api to listen for select events on the chart
    google.visualization.events.addListener(chart, "select", selectHandler);
  }

  useEffect(() => {
    // default the graph showing the light sleep tooltip
    if (google && chart && currentSleep){
      let selection = chart.getSelection();
      if (currentSleep.stage_2 > 0 && !selection.length){
        chart.setSelection([{row: 3, column: null}]);
        $(".google-visualization-tooltip").css({
          left: `77px`,
          top: `247px`,
        });
      }
    }
  }, [chart, currentSleep])

  // if current selection and user click away from donut, toggle selection off
  const clickHandler = (e) => {
    if (google && chart) {
      if (e.target.tagName !== "path") {
        if (chart.getSelection().length) {
          chart.setSelection([]);
        }
      }
    }
  };

  return (
    <>
      <div className="daily-sleep-chart-container">
        {(!google || loading) && <ClipLoader />}
        {google && !loading && (
          <div className="donutCell" id="donut-cell">
            <div
              onClick={(e) => clickHandler(e)}
              id="daily-sleep-chart"
              className="donut-div"
            />
            <div className="center-label">
              <DonutContent sleepGoal={sleepGoal} duration={currentSleep?.duration || 0} stage4={currentSleep?.stage_4 || 0} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
