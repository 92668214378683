import React, { useContext, useEffect, useState } from "react";
import {
  currentDayCode,
  dayCodeToDate,
  dayCodeToWeekday,
  getDistanceDisplay,
  getWorkoutBarStyle,
  getWeeklyDistanceFormat,
  getDailyDistanceFormat,
  getAscentFormat,
  getOS,
  minutesToTime,
  msToTime,
} from "../../Utility";
import $ from "jquery";
import { maxBy, minBy, findIndex, sumBy } from "lodash-es";
import DataService from "../../DataService";
import { Context } from "../../Store";
import BeatLoader from "../loaders/BeatLoader";
import PubSub from "pubsub-js";
import I18n from "../../Translation";

export default function WorkoutCard({ title }) {
  const OS = getOS();
  const [state, dispatch] = useContext(Context);
  const distanceWorkouts = ["cycling", "running", "swim"];
  let currentDayInt = dayCodeToDate(currentDayCode()).getDay();
  let className = `${title.replace(/_/g, "-").toLowerCase()}-card`;
  const [workoutData, setWorkoutData] = useState([]);
  const [currentItem, setCurrentItem] = useState({});
  const [initialScroll, setInitialScroll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [makingRequest, setMakingRequest] = useState(false);
  const [pageNumber, setPageNumber] = useState(0); // current week is page 0, previous is 1, etc

  let maxValue = null;
  if (workoutData.length) {
    maxValue = distanceWorkouts.includes(title)
      ? maxBy(workoutData, "distance").distance
      : maxBy(workoutData, "time").time;
  }
  let emptyWorkoutData =
    workoutData.length &&
    sumBy(workoutData, (o) => {
      if (distanceWorkouts.includes(title)) {
        return o.distance;
      }
      return o.time;
    }) === 0;

  // detect changes in scrolling and set the current item
  const scrollEvent = () => {
    let graphItems = $(`.${className} .graph-item`);
    graphItems.each((index, item) => {
      let graphItemX = item.getBoundingClientRect().x;
      let math = $(window).width() / graphItemX;
      let string = math.toString().slice(0, 3);
      if (string === "2.0") {
        setCurrentItem((currentItem) => {
          return {
            ...currentItem,
            index: index,
          };
        });
      }
    });
  };

  const checkHistory = (title) => {
    if (workoutData.length) {
      switch (title) {
        case "cycling":
          return DataService.minDayBike < workoutData[6].dayCode;
        case "swim":
          return DataService.minDaySwim < workoutData[6].dayCode;
        case "strength":
          return DataService.minDayGym < workoutData[6].dayCode;
        case "running":
          return DataService.minDayRun < workoutData[6].dayCode;
        default:
          return true;
      }
    }
  };

  const loadPreviousWeek = () => {
    setInitialScroll(false);
    setPageNumber(pageNumber + 1);
    let lastItemCode = minBy(workoutData, "dayCode").dayCode;
    if (!makingRequest) {
      setMakingRequest(true);
      setLoading(true);
      DataService.getWorkoutData(
        lastItemCode - 7,
        lastItemCode - 1,
        title
      ).then((newWorkoutData) => {
        setWorkoutData(newWorkoutData);
        setLoading(false);
        setMakingRequest(false);
      });
    }
  };

  const loadNextWeek = () => {
    setPageNumber(pageNumber - 1);
    let firstItemCode = maxBy(workoutData, "dayCode").dayCode;
    if (!makingRequest) {
      setMakingRequest(true);
      setLoading(true);
      DataService.getWorkoutData(
        firstItemCode + 1,
        firstItemCode + 7,
        title
      ).then((newWorkoutData) => {
        // this setTimeout ensures users will see a loading animation, and new bars will render in an organized fashion
        setWorkoutData(newWorkoutData);
        setLoading(false);
        setMakingRequest(false);
      });
    }
  };

  // load current week's data on page load
  useEffect(() => {
    if (!makingRequest) {
      setMakingRequest(true);
      setLoading(true);
      let startDayCode =
        currentDayInt === 0
          ? currentDayCode() - 6
          : currentDayCode() - (currentDayInt - 1);
      let endDayCode =
        currentDayInt === 0
          ? currentDayCode()
          : currentDayCode() + (7 - currentDayInt);
      DataService.getWorkoutData(startDayCode, endDayCode, title).then(
        (newWorkoutData) => {
          setWorkoutData([...workoutData, ...newWorkoutData]);
          setLoading(false);
          setMakingRequest(false);
        }
      );
    }
  }, []);

  // mount pub sub listener on first render only
  useEffect(() => {
    let token = PubSub.subscribe("daycodechanged", function (msg, data) {
      DataService.getWorkoutData(data.dayCode, data.dayCode, title).then(
        (records) => {
          // State is stale, so use setter to access current state
          setWorkoutData((prevWorkouts) => {
            let newWorkouts = [...prevWorkouts];
            let index = findIndex(newWorkouts, {
              dayCode: records[0].dayCode,
            });
            if (index >= 0) {
              newWorkouts[index] = records[0];
            }
            return newWorkouts;
          });
        }
      );
    });
    return function cleanup() {
      console.log(`WorkoutCard[unsubscribe] token: ${token}`);
      PubSub.unsubscribe(token);
    };
  }, []);

  const Time = () => {
    let totalTime = sumBy(workoutData, (o) => o.time);
    let goal = state.targetGoals.weekly_active_time;
    let color = "#0e0e0e";
    if (goal > 0 && title === "workout_time") {
      if (totalTime >= goal * 60000) {
        color = "#329C45";
      } else if (totalTime < goal * 60000) {
        color = "#DE9332";
      }
      return (
        <div style={{ color: color }}>{`${msToTime(
          totalTime
        )} / ${minutesToTime(goal)}`}</div>
      );
    }
    return <div>{`${msToTime(totalTime)}`}</div>;
  };

  const Ascent = () => {
    let totalAscent = sumBy(workoutData, (o) => o.ascent);
    let showElevation = ["cycling", "running", "workout_time"];
    if (showElevation.includes(title)) {
      return (
        <div className="info-item ascent">
          <div>{I18n.t("javascript.statistics.statistics_page.elevation")}</div>
          <div className="metric">
            {getAscentFormat(totalAscent, DataService.displayPreference)}
          </div>
        </div>
      );
    }
    return null;
  };

  const Distance = () => {
    let values = getWeeklyDistanceFormat(
      sumBy(workoutData, (o) => o.distance),
      state.targetGoals,
      title,
      DataService.displayPreference
    );
    let localeString = (value) => {
      return value.toLocaleString(undefined, {
        minimumFractionDigits: title === "swim" ? 0 : 1,
        maximumFractionDigits: title === "swim" ? 0 : 1,
      });
    };
    let color = "#0e0e0e";
    if (values.goal > 0) {
      if (values.current >= values.goal) {
        color = "#329C45";
      } else if (values.current < values.goal) {
        color = "#DE9332";
      }
      return (
        <div style={{ color: color }}>{`${localeString(
          values.current
        )} / ${localeString(values.goal)}`}</div>
      );
    }
    return <div>{`${localeString(values.current)}`}</div>;
  };

  useEffect(() => {
    //set scroll position of workout cards based on weekday
    if (workoutData.length && initialScroll === false && pageNumber === 0) {
      let days = [-10, -130, -110, -90, -70, -50, -30];
      if (OS === "ios") {
        days = [-130, -10, -30, -50, -70, -90, -110];
      }
      let currentDayInt = dayCodeToDate(currentDayCode()).getDay();
      let index = currentDayInt === 0 ? 0 : 7 - currentDayInt;
      setCurrentItem({ index: index });
      $(".workout-card .scroll-container").scrollLeft(days[currentDayInt]);
      setTimeout(() => {
        setInitialScroll(true);
      });
    }
  });

  return (
    <div className={`workout-card card-body ${className}`}>
      <div className="card-top-info">
        <div className="card-top-info-date">
          <div
            onClick={checkHistory(title) ? () => loadPreviousWeek() : null}
            className="card-top-info-button"
          >
            {checkHistory(title) && (
              <img
                className="workout-icon"
                src="/static/left.svg"
                alt="previous"
                height={12}
              />
            )}
          </div>
          <div>
            {workoutData[currentItem.index] &&
              dayCodeToDate(workoutData[currentItem.index].dayCode, true)}
          </div>
          <div
            onClick={pageNumber > 0 ? () => loadNextWeek() : null}
            className="card-top-info-button"
          >
            {pageNumber > 0 && (
              <img
                className="workout-icon"
                src="/static/right.svg"
                alt="next"
                height={12}
              />
            )}
          </div>
        </div>
        <div className="data">
          {workoutData[currentItem.index] && distanceWorkouts.includes(title)
            ? getDailyDistanceFormat(
                workoutData[currentItem.index].distance,
                title,
                DataService.displayPreference
              )
            : workoutData[currentItem.index] &&
              msToTime(workoutData[currentItem.index].time, true)}
        </div>
        <div className="arrow-line">
          <div className="bottom-arrow" />
        </div>
      </div>
      {loading && (
        <div className="workout-content-loading">
          <BeatLoader />
        </div>
      )}
      {!loading && !emptyWorkoutData && (
        <div className="workout-content">
          <div onScroll={() => scrollEvent()} className="scroll-container">
            <div className="offset">
              <div className="graph-item-container">
                {workoutData &&
                  workoutData.map((obj, index) => {
                    let style = getWorkoutBarStyle(obj, title, maxValue);
                    return (
                      <div
                        style={{
                          height: style.height + "px",
                          backgroundColor: style.color,
                        }}
                        key={index}
                        className="graph-item snap-align-center"
                      />
                    );
                  })}
              </div>
              {workoutData.length === 7 && (
                <div className="weekday-container">
                  {Array.from(Array(7).keys()).map((obj, index) => {
                    let weekday = workoutData.length
                      ? workoutData[index].dayCode
                      : 0;
                    return (
                      <div key={index} className="weekday">
                        {dayCodeToWeekday(weekday)}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {!loading && emptyWorkoutData && (
        <div className="workout-content align-items-center">
          <div className="empty-description p-4">
            {I18n.t(
              "javascript.statistics.statistics_page.empty_workout_description"
            )}
          </div>
        </div>
      )}
      <div className="workout-info">
        <div className="weekly-total">
          {I18n.t("javascript.statistics.statistics_page.weekly_total")}
        </div>
        {(title === "workout_time" || title === "strength") && (
          <div className="info-item workouts">
            <div>
              {I18n.t("javascript.statistics.statistics_page.workouts")}
            </div>
            <div className="metric">
              {sumBy(workoutData, (o) => o.workoutCount)}
            </div>
          </div>
        )}
        {title !== "strength" && (
          <div className="info-item distance">
            <div>
              {getDistanceDisplay(title, DataService.displayPreference)}
            </div>
            <div className="metric">
              <Distance />
            </div>
          </div>
        )}
        <div className="info-item time">
          <div>{I18n.t("javascript.statistics.statistics_page.time")}</div>
          <div className="metric">
            <Time />
          </div>
        </div>
        <Ascent />
      </div>
    </div>
  );
}
