import React from "react";
import { useSwipeable } from "react-swipeable";

export const CarouselItem = (props) => {
  return <>{props.children}</>;
};

const Carousel = ({ children, updateIndex, activeIndex }) => {
  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1),
  });

  return (
    <div {...handlers} className="swiper">
      <div className="swiper-inner">
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, { width: "100%" });
        })}
      </div>
    </div>
  );
};

export default Carousel;
