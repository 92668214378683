import React, { useEffect, useState, useContext } from "react";
import ClipLoader from "./loaders/ClipLoader";
import GoogleContext from "../GoogleContext";
import $ from "jquery";
import {getChartInterval, getChartWidth, prefersDarkMode, getPlaceHolderX, currentDayCode} from "../Utility";

export default function HrvChart({ chartCount, currentItem, setCurrentItem, currentItemDayCode, setCurrentItemDayCode }) {
  const { google } = useContext(GoogleContext);
  const [chart, setChart] = useState(null);
  const [reactData, setReactData] = useState(null);
  const [initialScroll, setInitialScroll] = useState(false);
  let size = window.screen.width;

  const buildEmpty = () => {
    let empty = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "circle"
    );
    empty.setAttribute("r", "4");
    empty.setAttribute("fill", "#ACB3B9");
    empty.setAttribute("cy", "85");
    return empty;
  };

  const buildData = () => {
    let graphData = [];
    Array.from(Array(chartCount)).forEach(() => {
      graphData.push(["pad", null]);
    });
    if(chartCount === 7){
      graphData.push(["pad", null])
    }
    Array.from(Array(chartCount)).forEach((item, index) => {
      graphData.splice(Math.round(chartCount / 2), 0, [null, null]);
    });
    // simulate a missing day by changing to [null, null]
    // graphData[chartCount + 3] = [null, null];
    return graphData;
  };


  const scrollEvent = () => {
    // the svg element under the arrow
    let chartItems = $("#hrv-chart circle").not("#first-dot-placeholder")
    chartItems.each((index, item) => {
      let graphItemX = item.getBoundingClientRect().x;
      let math = $(window).width() / graphItemX;
      let string = math.toString().slice(0, 3);
      if (string === "2.0") {
        setCurrentItem(parseInt(item.getAttribute('data-value')))
        setCurrentItemDayCode(parseInt(item.getAttribute('data-day')))
      }
    });

  }

  useEffect(() => {
    if (google) {
      //  Create the data table
      const data = new google.visualization.DataTable();
      data.addColumn("string", "day");
      data.addColumn("number", "hrv");
      let graphData = buildData(); //  Test Data
      data.addRows(graphData);
      setReactData(graphData);
      let options = {
        legend: "none",
        chartArea: { height: "90%", width: "90%" },
        width: getChartWidth(size, chartCount),
        height: 90,
        backgroundColor: { fill: "transparent" },
        enableInteractivity: false,
        pointSize: 6,
        lineWidth: 1,
        colors: ["#007faa"],
        vAxis: {
          gridlines: { color: "#bcbcbc", count: 5 },
          baselineColor: "transparent",
          minorGridlines: { count: 0 },
          viewWindow: { max: 95 },
        },
        interpolateNulls: true,
        hAxis: { textPosition: "none" },
      };
      // Instantiate and draw the chart
      const newChart = new google.visualization.LineChart(
        document.getElementById("hrv-chart")
      );
      newChart.draw(data, options);
      setChart(newChart);
    }
  }, [google, chartCount]);

  if (google && chart) {
    // change the chart gridlines to dashed
    let gridLines = chart.getContainer().getElementsByTagName("rect");
    Array.prototype.forEach.call(gridLines, function (line) {
      if (line.getAttribute("fill") === "#bcbcbc") {
        line.setAttribute("fill", "url(#pattern-fill) #bcbcbc");
      }
    });
    // clone vAxis label <g>, add to our own svg so they will not scroll with graph
    let Root = document.getElementById("hrv-label");
    let group = document.querySelector(
      "#hrv-chart svg > g:nth-child(2) > g:nth-child(4)"
    );
    let hrvLabels = document.querySelector(
      ".hrv-chart-container #hrv-label rect"
    );
    let svg = document.querySelector(".hrv-chart-container #hrv-label");
    if (group && !hrvLabels) {
      let NewG = group.cloneNode(true);
      Root.appendChild(NewG);
      $("#hrv-chart svg g:nth-child(2) g:nth-child(4)").remove();
    }
    // select all the y axis labels
    if (!hrvLabels) {
      let collection = $("#hrv-label g > g");
      if (collection.length) {
        $(collection).each((index, element) => {
          let textElm = $(element).find("text")[0];
          SVGRect = textElm.getBBox();
          let rect = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "rect"
          );
          // draw gray boxes behind them
          rect.setAttribute("x", SVGRect.x - 4);
          rect.setAttribute("y", SVGRect.y - 1);
          rect.setAttribute("width", "20");
          if ($(textElm).html().length > 2) {
            rect.setAttribute("width", "30");
          }
          if ($(textElm).html().length < 2) {
            rect.setAttribute("x", SVGRect.x - 10);
          }
          rect.setAttribute("height", "15");
          rect.setAttribute("opacity", ".6");
          if (prefersDarkMode()) {
            textElm.setAttribute("fill", "#000");
            rect.setAttribute("fill", "#fff");
          } else {
            rect.setAttribute("fill", "#ACB3B9");
          }
          svg.insertBefore(rect, svg.firstChild);
        });
      }
    }

    // create a placeholder circle that always lives at the start of the graph
    let placeholder = buildEmpty();
    placeholder.setAttribute("opacity", "0");
    placeholder.setAttribute("cx", `${getPlaceHolderX(chartCount, size)}`);
    placeholder.setAttribute("id", "first-dot-placeholder");
    if(!$("#hrv-chart #first-dot-placeholder").length){
      $("#hrv-chart svg g g")[0].appendChild(placeholder);
    }

    // find the circles on the line
    let circles = $("#hrv-chart svg > g:nth-child(2) circle");
    let placeholderX = $(".hrv-chart-container #first-dot-placeholder")[0].cx.baseVal.value;
    let interval = getChartInterval(chartCount, size);
    if (circles.length) {
      if (reactData.length) {
        reactData.forEach((item, index) => {
          if (item[0] === null && item[1] === null) {
            // fill in missing spots with gray circles
            let circle = buildEmpty();
            circle.setAttribute('class', 'empty-circle')
            circle.setAttribute('data-value', 0)
            circle.setAttribute(
              "cx",
              `${
                placeholderX +
                interval.interval * (index - interval.nullCount || 0)
              }`
            );
            let start = (reactData.length - 1) - interval.nullCount
            circle.setAttribute('data-day', currentDayCode() - (start - index))
            $("#hrv-chart svg")[0].appendChild(circle);
            reactData[index] = [`dayCode:${currentDayCode() - (start - index)}`, 0]

          }
        });
      }
      function getSorted(selector) {
        return $($(selector).toArray().sort(function(a, b){
          let aVal = parseInt(a.getAttribute('cx')),
              bVal = parseInt(b.getAttribute('cx'));
          return aVal - bVal;
        }));
      }
      let sortedCircles = getSorted($("#hrv-chart circle").not("#first-dot-placeholder"))
      if(sortedCircles.length === chartCount){
        sortedCircles.each(function (index){
          if($(this).hasClass('empty-circle')){
            //  already has data on svg
          } else {
            if(reactData[index + interval.nullCount]){
              $(this).attr('data-value', reactData[index + interval.nullCount][1])
              $(this).attr('data-day', reactData[index + interval.nullCount][0].split(':')[1])
            }
          }
        })
      }

    }
    if (!circles.length) {
      let interval = getChartInterval(chartCount, size);
      Array.from(Array(chartCount)).forEach((item, index) => {
        let empty = buildEmpty();
        empty.setAttribute('data-value', 0)
        empty.setAttribute("cx", `${placeholderX + (interval.interval * index)}`);
        $("#hrv-chart g")[0].appendChild(empty);
      });
    }
    if(!initialScroll){
      if(chartCount === 7){
        $(".chart-scroll").scrollLeft(size * .95)
      } else {
        $(".chart-scroll").scrollLeft(size * .95)
      }
    }
    setTimeout(() => {
      setInitialScroll(true);
    }, 10);
  }

  return (
    <>
      <div className="hrv-chart-container">
        {!google && <ClipLoader />}
        {google && (
          <>
            <div onScroll={() => scrollEvent()} className="chart-scroll">
              <div className="hrv-chart-wrapper">
                <div id="hrv-chart" />
              </div>
            </div>
          </>
        )}
        <svg
          style={{ width: 0, height: 0, position: "absolute" }}
          aria-hidden="true"
          focusable="false"
        >
          <pattern
            id="pattern-fill"
            x="0"
            y="0"
            width="8"
            height="8"
            patternUnits="userSpaceOnUse"
            patternTransform="rotate(30)"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="8"
              style={{ stroke: "none", fill: "#bcbcbc" }}
            />
          </pattern>
        </svg>
        <svg
          style={{ position: "absolute" }}
          focusable="false"
          id="hrv-label"
        />
      </div>
    </>
  );
}
