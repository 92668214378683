import React, { useState, useEffect, useContext } from "react";
import GoogleContext from "../GoogleContext";
import ClipLoader from "./loaders/ClipLoader";
import $ from "jquery";
import {
  dayCodeToWeekday,
  minutesToDecimal,
  minutesToTime,
} from "../Utility";
import { Context } from "../Store";
import { sumBy } from "lodash-es";

export default function WeeklySleepChart({ weeklySleep, isDark }) {
  let emptyWeek =
    sumBy(weeklySleep, (o) => {
      return o.duration;
    }) === 0;
  const { google } = useContext(GoogleContext);
  const [state, dispatch] = useContext(Context);
  const [chart, setChart] = useState(null);
  const [data, setData] = useState(null);
  let sleepScores = [0, 0, 0, 0, 0, 0, 0];
  let sleepGoal = state.targetGoals.daily_sleep
    ? minutesToDecimal(state.targetGoals.daily_sleep)
    : null;

  useEffect(() => {
    if (google) {
      const data = new google.visualization.DataTable();
      data.addColumn("string", "day");
      data.addColumn("number", "Uncategorized");
      data.addColumn("number", "Deep Sleep");
      data.addColumn("number", "Light Sleep");
      data.addColumn("number", "REM");
      let graphData = [
        [
          "m",
          weeklySleep[0].stage_0 / 3600,
          weeklySleep[0].stage_1 / 3600,
          weeklySleep[0].stage_2 / 3600,
          weeklySleep[0].stage_3 / 3600,
        ],
        [
          "t",
          weeklySleep[1].stage_0 / 3600,
          weeklySleep[1].stage_1 / 3600,
          weeklySleep[1].stage_2 / 3600,
          weeklySleep[1].stage_3 / 3600,
        ],
        [
          "w",
          weeklySleep[2].stage_0 / 3600,
          weeklySleep[2].stage_1 / 3600,
          weeklySleep[2].stage_2 / 3600,
          weeklySleep[2].stage_3 / 3600,
        ],
        [
          "t",
          weeklySleep[3].stage_0 / 3600,
          weeklySleep[3].stage_1 / 3600,
          weeklySleep[3].stage_2 / 3600,
          weeklySleep[3].stage_3 / 3600,
        ],
        [
          "f",
          weeklySleep[4].stage_0 / 3600,
          weeklySleep[4].stage_1 / 3600,
          weeklySleep[4].stage_2 / 3600,
          weeklySleep[4].stage_3 / 3600,
        ],
        [
          "s",
          weeklySleep[5].stage_0 / 3600,
          weeklySleep[5].stage_1 / 3600,
          weeklySleep[5].stage_2 / 3600,
          weeklySleep[5].stage_3 / 3600,
        ],
        [
          "s",
          weeklySleep[6].stage_0 / 3600,
          weeklySleep[6].stage_1 / 3600,
          weeklySleep[6].stage_2 / 3600,
          weeklySleep[6].stage_3 / 3600,
        ],
      ];
      if (sleepGoal) {
        data.addColumn("number", ""); // this column draws the goal line on the chart by using a stepped area
        graphData.forEach((item) => {
          item.push(sleepGoal); // add sleep goal as the seventh column in these rows
        });
      }
      if (emptyWeek) {
        // build the chart data to match design if the week has no data
        const createEmptyRow = (weekday) => {
          if (!sleepGoal) {
            return [weekday, 0.5, 2, 2, 2];
          } else {
            return [weekday, 0.5, 2, 2, 2, sleepGoal];
          }
        };
        data.addRows(graphData.map(gd => createEmptyRow(gd[0])));
      } else {
        data.addRows(graphData);
      }
      // TODO: localize these day letters once the data service method for sleep exists using dayCodeToWeekday()
      // to include a column with role of style, all columns must contain the value
      setData(data);
      let options = {
        chartArea: { width: "85%", height: "75%" },
        height: 220,
        legend: "none",
        bar: { groupWidth: "25%" },
        isStacked: true,
        enableInteractivity: false,
        backgroundColor: isDark ? "#1e1e1e" : "#ffffff",
        vAxis: {
          textStyle: { color: (isDark ? "#1e1e1e" : "#ffffff"), textAlign: "center" },
          gridlines: { color: "#bcbcbc", count: 12, multiple: 2 },
          baselineColor: "transparent",
          minorGridlines: { count: 0 },
        },
        hAxis: {
          textStyle: { color: (isDark ? "#ffffff" : "#222222"), textAlign: "center" },
        },
        series: {
          // draw the colored sections transparent if the week is empty
          0: { color: "#C4C4C4" },
          // 1: { color: emptyWeek ? "transparent" : "#60B7D9" },
          1: { color: emptyWeek ? "transparent" : "#00375A" },
          2: { color: emptyWeek ? "transparent" : "#0098CE" },
          3: { color: emptyWeek ? "transparent" : "#006293" },
          // draw goal line in red to distinguish it from other svg elements
          4: {
            type: "steppedArea",
            color: "#FF0000",
            visibleInLegend: false,
            areaOpacity: 0,
          },
        },
      };
      // Instantiate and draw the chart
      const newChart = new google.visualization.ColumnChart(
        document.getElementById("weekly-sleep-chart")
      );
      newChart.draw(data, options);
      setChart(newChart);
    }
  }, [google, weeklySleep, isDark]);

  if (google && chart) {
    // change the chart gridlines to dashed
    let gridLines = chart.getContainer().getElementsByTagName("rect");
    Array.prototype.forEach.call(gridLines, function (line) {
      if (line.getAttribute("fill") === "#bcbcbc") {
        line.setAttribute("fill", "url(#pattern-fill) #bcbcbc");
      }
    });

    // select all the axis labels
    let svg = document.querySelector("#weekly-sleep-chart svg");
    let appendNode = document.querySelector(
      "#weekly-sleep-chart svg > g:nth-child(3) > g:nth-child(4)"
    );
    // chart text labels
    let collection = $(
      "#weekly-sleep-chart svg g:nth-child(3) g:nth-child(4) g"
    );
    // draw gray boxes behind each one
    $(collection).each((index, element) => {
      //  isolate the y axis labels
      if (index > 6 && index < collection.length) {
        let textElm = $(element).find("text")[0];
        SVGRect = textElm.getBBox();
        let rect = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "rect"
        );

        if (index < 12) {
          // the labels without 2 digits
          rect.setAttribute("x", SVGRect.x - 10);
          textElm.setAttribute("x", textElm.getAttribute("x") - 3);
        } else if (index >= 12) {
          // the labels with 2 digits
          rect.setAttribute("x", SVGRect.x - 4);
        }
        rect.setAttribute("y", SVGRect.y - 3);
        rect.setAttribute("width", "20");
        rect.setAttribute("height", SVGRect.height + 6);
        if (isDark) {
          textElm.setAttribute("fill", "#000");
          rect.setAttribute("fill", "#fff");
        } else {
          rect.setAttribute("fill", "#ACB3B9");
        }
        svg.insertBefore(rect, appendNode.parentElement);
      }
    });

    //  select all the deep sleep rects, they are at the top of each bar
    $("#weekly-sleep-chart rect[fill='#006886']").each((index, item) => {
      // let sleepScore = sleepScores[index];
      // let barTopRect = item.getBBox();
      // let circle = document.createElementNS(
      //   "http://www.w3.org/2000/svg",
      //   "circle"
      // );
      // TODO: uncomment this code to show sleep scores when the algorithm is finished
      // append sleep score circle and text to the top of the bar
      // circle.setAttribute("cx", `${barTopRect.x + barTopRect.width / 2}`);
      // circle.setAttribute("cy", `${barTopRect.y - 11}`);
      // circle.setAttribute("r", `10`);
      // if (isDark) {
      //   circle.setAttribute("fill", "#bcbcbc");
      // } else {
      //   circle.setAttribute("fill", "#273C3D");
      // }
      // circle.setAttribute("class", "weekly-sleep-score-circle");
      // let text = document.createElementNS("http://www.w3.org/2000/svg", "text");
      // //single digit sleep scores
      // if (sleepScore <= 9) {
      //   text.setAttribute("x", `${barTopRect.x + 3}`);
      // }
      // // two digit sleep scores
      // else if (sleepScore > 9) {
      //   text.setAttribute("x", `${barTopRect.x}`);
      // }
      // text.setAttribute("y", `${barTopRect.y - 7}`);
      // if (isDark) {
      //   text.setAttribute("fill", "#000");
      // } else {
      //   text.setAttribute("fill", "#fff");
      // }
      // text.setAttribute("font-size", "11px");
      // text.textContent = `${sleepScore}`;
      // svg.insertBefore(circle, item.firstChild);
      // svg.insertBefore(text, item.firstChild);
    });

    // change the goal line, purposefully drawn in red, to black
    // modify the svg line to be dashed
    if (sleepGoal) {
      let goalLines = chart.getContainer().getElementsByTagName("path");
      Array.prototype.forEach.call(goalLines, function (line) {
        if (line.getAttribute("stroke") === "#ff0000") {
          if (isDark) {
            line.setAttribute("stroke", "#fff");
          } else {
            line.setAttribute("stroke", "#000");
            line.setAttribute("stroke-width", "1");
          }
          line.setAttribute("stroke-dasharray", "5,5");
          line.setAttribute("class", "goal-line-segment");
        }
      });

      // create a css variable to place .sleep-goal-indicator above the line
      let goalLinePosition = $(
        "#weekly-sleep-chart .goal-line-segment"
      )[0].getBBox();
      document.documentElement.style.setProperty(
        "--sleep-goal-height",
        `${goalLinePosition.y}px`
      );
    }
  }

  return (
    <div className="weekly-sleep-chart-container">
      {!google && <ClipLoader />}
      {google && (
        <>
          <div id="weekly-sleep-chart" />
          {/*// TODO: uncomment this code to show sleep scores when the algorithm is finished */}
          {/*<div className="sleep-score-label-container">*/}
          {/*  <div className="sleep-score-label">*/}
          {/*    {I18n.t("javascript.statistics.statistics_page.sleep_score")}*/}
          {/*  </div>*/}
          {/*</div>*/}
          {sleepGoal && (
            <div className="sleep-goal-indicator">
              {minutesToTime(state.targetGoals.daily_sleep)}
            </div>
          )}
        </>
      )}
      <svg
        style={{ width: 0, height: 0, position: "absolute" }}
        aria-hidden="true"
        focusable="false"
      >
        <pattern
          id="pattern-fill"
          x="0"
          y="0"
          width="8"
          height="8"
          patternUnits="userSpaceOnUse"
          patternTransform="rotate(30)"
        >
          <rect
            x="0"
            y="0"
            width="4"
            height="8"
            style={{ stroke: "none", fill: "#bcbcbc" }}
          />
        </pattern>
      </svg>
    </div>
  );
}
