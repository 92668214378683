import React, { useState, useEffect, useContext } from "react";
import {
  dayCodeToDate,
  currentDayCode,
  getBarColor,
  getGoalLineHeight,
  getBarHeight,
} from "../../Utility";
import { Context } from "../../Store";
import DataService from "../../DataService";
import ClipLoader from "../loaders/ClipLoader";
import PubSub from "pubsub-js";
import $ from "jquery";
import { minBy, maxBy, findIndex, take } from "lodash-es";
import I18n from "../../Translation";

export default function StepsCard() {
  const [state, dispatch] = useContext(Context);
  const [allSteps, setAllSteps] = useState([]);
  let lastItemCode = allSteps.length
    ? minBy(allSteps, "dayCode").dayCode
    : currentDayCode() + 1;
  const [currentItem, setCurrentItem] = useState({ index: 0 });
  const [loading, setLoading] = useState(false);
  const [makingRequest, setMakingRequest] = useState(false);
  const [initialScroll, setInitialScroll] = useState(false);
  const [maxReading, setMaxReading] = useState(10000); // highest step count in the viewport
  const [inViewport, setInViewport] = useState([]); // array of step counts in the viewport
  const [goalHeight, setGoalHeight] = useState(null); // goal indicator bottom position in pixels
  const [isActive, setIsActive] = useState(true); // scroll container touch event state
  let allGoals = "normal";
  let scrollFix = $("body").hasClass("ios ios-scroll-fix");
  let emptyStepHistory = null;
  if (
    !DataService.userDailySummaries.length &&
    DataService.minDaySteps === currentDayCode
  ) {
    emptyStepHistory = true;
  }

  useEffect(() => {
    if (scrollFix) {
      $(".steps-card .scroll-container").scrollLeft(-790);
      setTimeout(() => {
        setInitialScroll(true);
      }, 10);
    }
    console.log(`StepsCard[useEffect] componentDidMount`);
    let token = PubSub.subscribe("daycodechanged", function (msg, data) {
      DataService.getSteps(data.dayCode, data.dayCode).then((records) => {
        // State is stale, so use setter to access current state
        setAllSteps((prevSteps) => {
          console.log(
            `StepsCard[useEffect] NewStep: ${
              records[0].dayCode
            } | ${JSON.stringify(records[0])}`
          );
          let newSteps = [...prevSteps];
          let index = findIndex(newSteps, { dayCode: records[0].dayCode });
          console.log(
            `StepsCard[useEffect] OldStep Index: ${index} | ${JSON.stringify(
              newSteps[index]
            )}`
          );
          if (index >= 0) {
            newSteps[index] = records[0];
          }
          return newSteps;
        });
      });
    });
    return function cleanup() {
      console.log(`StepsCode[unsubscribe] token: ${token}`);
      PubSub.unsubscribe(token);
    };
  }, []);

  // detect changes in scrolling and set the current item
  const scrollEvent = () => {
    let graphItems = $(".steps-card .graph-item");
    graphItems.each((index, item) => {
      let graphItemX = item.getBoundingClientRect().x;
      let math = $(window).width() / graphItemX;
      let string = math.toString().slice(0, 3);
      if (string === "2.0") {
        setCurrentItem((currentItem) => {
          return {
            ...currentItem,
            index: index,
          };
        });
      }
    });
  };

  // older ios versions don't respect RTL and snap.  This jquery prevents over-scrolling.
  $(document).ready(() => {
    if (scrollFix) {
      //  overscroll to the left
      let scrollEdge = $(".steps-card .scroll-container").scrollLeft();
      if (scrollEdge === 0 || scrollEdge === 1) {
        $(".steps-card .scroll-container").scrollLeft(-10);
      }
      // overscroll to the right
      $(".steps-card .scroll-container").on("scroll", function () {
        if (initialScroll) {
          let firstItem = $(".steps-card .graph-item").first();
          if (firstItem[0].getBoundingClientRect().x < 170) {
            $(".steps-card .scroll-container").css("overflow-x", "hidden");
            setTimeout(() => {
              $(".steps-card .scroll-container").css("overflow-x", "scroll");
            }, 100);
          }
        }
      });
    }
  });

  useEffect(() => {
    if (lastItemCode) {
      if (
        allSteps[currentItem.index]?.dayCode < lastItemCode + 2 ||
        currentDayCode() + 1 === lastItemCode
      ) {
        if (!makingRequest && !loading) {
          setMakingRequest(true);
          setLoading(true);
          DataService.getSteps(lastItemCode - 20, lastItemCode - 1).then(
            (newSteps) => {
              let concatSteps = [...allSteps, ...newSteps];
              if (!inViewport.length) {
                // load the page scaled using the first 12 records
                let max = maxBy(take(concatSteps, 12), "totalSteps").totalSteps;
                setMaxReading(max);
                setGoalHeight(
                  getGoalLineHeight(
                    max,
                    setMaxReading,
                    state.targetGoals.daily_steps,
                    "steps",
                    60000
                  )
                );
              }
              // this setTimeout ensures users will see a loading animation, and new bars will render in an organized fashion
              setTimeout(() => {
                setAllSteps(concatSteps);
                setLoading(false);
                setMakingRequest(false);
              }, 300);
            }
          );
        }
      }
    }
  }, [currentItem]);

  if (allSteps.length) {
    let metGoalCount = 0;
    let failedGoalCount = 0;
    allSteps.forEach((step) => {
      if (step.totalSteps >= state.targetGoals.daily_steps) {
        metGoalCount++;
      } else if (step.totalCalories < state.targetGoals.daily_steps) {
        failedGoalCount++;
      }
    });
    if (metGoalCount === allSteps.length) {
      allGoals = "pass";
    }
    if (failedGoalCount === allSteps.length) {
      allGoals = "fail";
    }
  }

  let currentStep = allSteps.length
    ? allSteps[currentItem.index]
    : { dayCode: currentDayCode() };

  $.fn.scrollEnd = function (callback, timeout) {
    $(this).on("scroll", function () {
      let $this = $(this);
      if ($this.data("scrollTimeout")) {
        clearTimeout($this.data("scrollTimeout"));
      }
      $this.data("scrollTimeout", setTimeout(callback, timeout));
    });
  };

  $.fn.isInViewport = function (rect) {
    if (rect.x > -5 && rect.x < $(window).width()) {
      return true;
    }
  };

  $(document).ready(() => {
    // if scrolling has come to an end, and the touch event is not active
    // calculate the items in the viewport and scale based on the max value
    $(".steps-card .scroll-container").scrollEnd(function () {
      if (!makingRequest && !loading && !isActive) {
        let items = $(".steps-card .graph-item");
        let list = [];
        items.each(function (index, item) {
          if ($(this).isInViewport(item.getBoundingClientRect())) {
            list.push({ total: $(this).data("step-total") });
          }
        });
        setInViewport(list);
        let max = maxBy(list, "total").total;
        setMaxReading(max);
        setGoalHeight(
          getGoalLineHeight(
            max,
            setMaxReading,
            state.targetGoals.daily_steps,
            "steps",
            60000
          )
        );
      }
    }, 300);
  });

  if (emptyStepHistory) {
    return (
      <div className="steps-card card-body">
        <div className="p-4 empty-description">
          {I18n.t(
            "javascript.statistics.statistics_page.empty_steps_description"
          )}
        </div>
      </div>
    );
  }
  return (
    <div className="steps-card card-body">
      <div className="loading-spinner">{loading && <ClipLoader />}</div>
      <div className="card-top-info">
        <div className="date">{dayCodeToDate(currentStep.dayCode, true)}</div>
        <div className="data-wrapper">
          <div className="data-description-left">
            <div className="count">{currentStep.inWorkoutSteps}</div>
            <div className="help-text">
              {I18n.t("javascript.statistics.statistics_page.in_workout")}
            </div>
          </div>
          <div className="vertical-rule" />
          <div className="data-description-right">
            <div className="count">
              {currentStep.totalSteps && currentStep.totalSteps}
            </div>
            <div className="help-text">
              {I18n.t("javascript.statistics.statistics_page.total")}
            </div>
          </div>
        </div>
        <div className="arrow-line">
          <div className="bottom-arrow" />
        </div>
      </div>
      {state.targetGoals.daily_steps > 0 && allSteps.length > 0 && (
        <div
          className="goal-indicators"
          style={{ bottom: "-" + goalHeight + "px" }}
        >
          <div className="dashed-line"></div>
          <div className="target-metric-wrapper">
            <div className="target-metric">{state.targetGoals.daily_steps}</div>
          </div>
        </div>
      )}
      <div
        onScroll={() => scrollEvent()}
        className="scroll-container"
        onTouchStart={() => setIsActive(true)}
        onTouchEnd={() => setIsActive(false)}
      >
        <div className="offset">
          <div className="graph-item-container">
            {(allSteps.length > 0 ? allSteps : [currentStep]).map(
              (obj, index) => {
                const style = getBarColor(
                  obj.totalSteps,
                  state.targetGoals.daily_steps
                );
                // new values for height
                const totalHeight = getBarHeight(
                  obj.totalSteps,
                  maxReading,
                  setMaxReading,
                  state.targetGoals.daily_steps,
                  "steps",
                  60000
                );
                const inWorkoutHeight = getBarHeight(
                  obj.inWorkoutSteps,
                  maxReading,
                  setMaxReading,
                  state.targetGoals.daily_steps,
                  "steps",
                  60000
                );
                return (
                  <div
                    style={{
                      height: totalHeight.height + "px",
                      backgroundColor: style.totalColor,
                    }}
                    key={index}
                    className="graph-item snap-align-center"
                    data-step-total={obj.totalSteps}
                  >
                    {obj.inWorkoutSteps > 0 && (
                      <div className="graph-item-overlay">
                        <div
                          style={{
                            height: inWorkoutHeight.height + "px",
                            backgroundColor: style.inWorkoutColor,
                          }}
                          className="in-workout"
                        />
                      </div>
                    )}
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
      {state.targetGoals.daily_steps > 0 && (
        <div className="legend-container">
          <div className={`legend in-workout-${allGoals}`} />
          <div className="legend-text">
            {I18n.t("javascript.statistics.statistics_page.in_workout")}
          </div>
          <div className={`legend total-${allGoals}`} />
          <div className="legend-text">
            {I18n.t("javascript.statistics.statistics_page.total_steps")}
          </div>
        </div>
      )}
    </div>
  );
}
