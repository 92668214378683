import React, { useContext, useEffect, useState } from "react";
import Carousel, { CarouselItem } from "./Carousel";
import { Context } from "../Store";
import { currentDayCode, iOS_version } from "../Utility";
import ClipLoader from "./loaders/ClipLoader";
import { LineChart } from "react-chartkick";
import "chartkick/chart.js";
import DataService from "../DataService";
import { map, omit } from "lodash-es";
import { hrGraphConfig } from "../Utility";
import { install } from "resize-observer";
import I18n from "../Translation";
import PubSub from "pubsub-js";

export default function HeartRateChart({ graphLoading, setGraphLoading }) {
  const iOS = iOS_version();
  if (iOS && iOS.Full_Release_Numeric < 13) {
    install();
  }
  // eslint-disable-next-line no-undef
  Chartkick.options = hrGraphConfig;

  const [state, dispatch] = useContext(Context);
  const [graphData, setGraphData] = useState({});
  const [activeIndex, setActiveIndex] = useState(state.activeHrIndex);

  const updateIndex = (newIndex) => {
    if (newIndex <= currentDayCode()) {
      setActiveIndex(newIndex);
      dispatch({ type: "set_active_hr_index", payload: newIndex });
    }
  };

  const handleUpdateData = (data) => {
    let array = map(data[state.activeHrIndex], function (o) {
      return omit(o, "strides");
    });
    // make data chartkick friendly
    let heartRates = Object.assign(
      {},
      ...array.map((item) => ({ [item.minute]: item.heartrate_bpm }))
    );
    setGraphData({ [`${state.activeHrIndex}`]: heartRates });
    setGraphLoading(false);
  };

  const handleUpdateEmpty = () => {
    // use an empty object to conditionally render empty data state
    setGraphData({ [`${state.activeHrIndex}`]: {} });
    setGraphLoading(false);
  };

  useEffect(() => {
    setGraphLoading(true);
    DataService.updateSummaryFileJSON(state.activeHrIndex).then((data) => {
      if (data[state.activeHrIndex]) {
        handleUpdateData(data);
      } else if (!data[state.activeHrIndex]) {
        handleUpdateEmpty();
      }
      setGraphLoading(false);
    });
  }, [state.activeHrIndex]);

  useEffect(() => {
    let token = PubSub.subscribe("daycodechanged", function (msg, data) {
      if (state.activeHrIndex === data.dayCode) {
        setGraphLoading(true);
        let hrData = DataService.summaryFileJsonData;
        if (hrData[data.dayCode]) {
          handleUpdateData(hrData);
        } else if (!hrData[data.dayCode]) {
          handleUpdateEmpty();
        }
      }
    });
    return function cleanup() {
      console.log(`HeartRateChart[unsubscribe] token: ${token}`);
      PubSub.unsubscribe(token);
    };
  }, [state.activeHrIndex]);

  return (
    <div>
      <Carousel updateIndex={updateIndex} activeIndex={activeIndex}>
        <CarouselItem>
          <div className="swiper-item">
            {graphLoading && (
              <div className="graph-loading">
                <ClipLoader />
              </div>
            )}
            {!graphLoading &&
              graphData[state.activeHrIndex] &&
              Object.keys(graphData[state.activeHrIndex]).length > 0 && (
                <div className="chart-container">
                  <LineChart
                    id="heart-rate-chart"
                    height="230px"
                    width="100%"
                    data={graphData[state.activeHrIndex]}
                    dataset={{
                      borderColor: "#E53935",
                      fill: true,
                      backgroundColor: "#E53935",
                    }}
                  />
                </div>
              )}
            {!graphLoading &&
              graphData[state.activeHrIndex] &&
              !Object.keys(graphData[state.activeHrIndex]).length && (
                <div className="empty-heart-rate-chart p-4 empty-description">
                  <div className="p-4">
                    <img
                      src="/static/heart_broken.svg"
                      alt="settings"
                      height={26}
                    />
                  </div>
                  <div>
                    {I18n.t(
                      "javascript.statistics.statistics_page.empty_hr_description"
                    )}
                  </div>
                </div>
              )}
          </div>
        </CarouselItem>
      </Carousel>
    </div>
  );
}
