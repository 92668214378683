import React from "react";

export default function CardHeader({ title, icon, RightIconComponent, betaLabel = false }) {
  return (
    <div className="card-header">
      <div className="card-title">
        <div className="header-icon">{icon}</div>
        <h2>{title}</h2>
      </div>
      <div className="card-right">
        { betaLabel && (
          <h3 className="beta">BETA</h3>
        )}
        {RightIconComponent}
      </div>
    </div>
  );
}
