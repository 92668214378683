import React, { Component } from "react";
import axios from "axios";

class NetworkStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connected: true,
    };
    let that = this;
    axios.interceptors.response.use(
      function (response) {
        console.log(`===NetworkSuccess: ${that.state.connected}===`);
        if (!that.state.connected) {
          that.setState({ connected: true });
        }
        return response;
      },
      function (error) {
        console.log(`===NetworkError: ${error.message}===`);
        if (error.message === "Network Error") {
          that.setState({ connected: false });
        }
        return Promise.reject(error);
      }
    );
  }

  render() {
    return (
      <div className={this.state.connected ? "d-none" : "network-status"}>
        Network not connected.
      </div>
    );
  }
}

export default NetworkStatus;
