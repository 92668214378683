import React, { useState } from "react";
import { minutesToTime, dateToDayCode, stripTime } from "../Utility";
import I18n from "../Translation";
import SleepSessionChart from "./SleepSessionChart";
import SleepSessionTimes from "./SleepSessionTimes";
import InfoModal from "./InfoModal";
import SleepTimePicker from "../pickers/SleepTimePicker";
import CloudService from "../CloudService";

export default function SleepSessions({ sleepSessions }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [sessionToEdit, setSessionToEdit] = useState(null);
  const [startTimeForUpdate, setStartTimeForUpdate] = useState(null);
  const [endTimeForUpdate, setEndTimeForUpdate] = useState(null);

  const updateSleepSession = async () => {
    console.log("start time for update", startTimeForUpdate);
    console.log("end time for update", endTimeForUpdate);
    let res = await CloudService.createSleepSessionEdit(sessionToEdit.id, startTimeForUpdate, endTimeForUpdate)
    if (res.status === 200){
    //  set the session into a loading/processing state
    }
  };

  // wait for the pub sub message

  const customActions = (
    <div className="d-flex justify-content-end">
      <div>
        <button
          className="info-modal-cancel-btn btn btn-link"
          onClick={() => setModalOpen(false)}
        >
          {I18n.t("javascript.statistics.utility.cancel")}
        </button>
        <button
          onClick={() => {
            updateSleepSession();
            setModalOpen(false);
          }}
          className="info-modal-confirm-btn"
        >
          {I18n.t("javascript.statistics.utility.apply")}
        </button>
      </div>
    </div>
  );
  return (
    <div>
      {sleepSessions.map((session, i) => {
        // these times will eventually be editable.  Hold on extracting to Utility.js.
        let start_date = new Date(`${session.start_time}`);
        let end_date = new Date(`${session.end_time}`);
        let start_time = start_date.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        });
        let end_time = end_date.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        });
        let day_abbr = I18n.t("date.abbr_day_names")[start_date.getDay()];
        let start_code = dateToDayCode(new Date(`${session.start_time}`));
        let end_code = dateToDayCode(new Date(`${session.end_time}`));
        const Weekday = () => {
          return <span className="ml-1">{day_abbr.toString()}</span>;
        };

        return (
          <div
            key={session.id}
            className="sleep-session-container"
            onClick={() => {
              setSessionToEdit(session);
              setModalOpen(true);
            }}
          >
            <div className="sleep-session-information">
              <div className="first">
                <img
                  className="workout-icon"
                  src="/static/sleep.svg"
                  alt="moon"
                  height={18}
                />
              </div>
              <div className="second">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="sleep-duration">
                    {minutesToTime(
                      Math.round((session.duration - session.stage_4) / 60),
                      "medium"
                    )}
                  </div>
                  <div className="sleep-time">
                    {stripTime(start_time)}
                    <span>{start_code < end_code && <Weekday />}</span>
                    {"\u00A0"}- {stripTime(end_time)}
                  </div>
                </div>
                <div className="sleep-time-small">
                  <div className="sleep-time">
                    {I18n.t("javascript.statistics.statistics_page.sleep_time")}
                  </div>
                  <div className="sleep-time">
                    {I18n.t(
                      "javascript.statistics.statistics_page.total_duration"
                    )}{" "}
                    {minutesToTime(Math.round(session.duration / 60))}
                  </div>
                </div>
              </div>
              <div className="third">
                <div className="session-edit">
                  <img
                    className="workout-icon"
                    src="/static/right.svg"
                    alt="arrow"
                    height={12}
                  />
                </div>
              </div>
            </div>
            <div className="sleep-session-chart-container">
              <SleepSessionChart session={session} key={session.updated_at} />
            </div>
            <div className="sleep-session-time-marks">
              <SleepSessionTimes
                session={session}
                start_time={start_time}
                end_time={end_time}
                start_date={start_date}
              />
            </div>
          </div>
        );
      })}
      <InfoModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        title={I18n.t("javascript.statistics.statistics_page.edit_sleep_time")}
        customActions={customActions}
      >
        <div className="w-100">
          <div className="edit-row">
            <label className="m-0 p-0">
              {I18n.t("javascript.statistics.statistics_page.time_sleep")}
            </label>
            <SleepTimePicker
              setTimeForUpdate={setStartTimeForUpdate}
              isStartTime={true}
              session={sessionToEdit}
              goalValue={300}
              updateGoal={() => {}}
            />
          </div>
          <div className="edit-row">
            <label className="m-0 p-0">
              {I18n.t("javascript.statistics.statistics_page.time_wake")}
            </label>
            <SleepTimePicker
              setTimeForUpdate={setEndTimeForUpdate}
              isStartTime={false}
              session={sessionToEdit}
              goalValue={300}
              updateGoal={() => {}}
            />
          </div>
        </div>
      </InfoModal>
    </div>
  );
}
