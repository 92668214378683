import React from "react";
import Modal from "react-modal";
import I18n from "../Translation";

export default function InfoModal({
  modalOpen,
  setModalOpen,
  title,
  children,
  customActions,
}) {
  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={() => setModalOpen(false)}
      className="info-modal"
      overlayClassName="info-modal-overlay"
      ariaHideApp={false}
    >
      <div className="info-modal-header">
        <div>{title}</div>
        <div className="info-modal-close" onClick={() => setModalOpen(false)}>
          <img
            className="workout-icon"
            src="/static/close.svg"
            alt="close"
            height={15}
          />
        </div>
      </div>
      <div className="info-modal-content">{children}</div>
      {!customActions && (
        <div className="info-modal-dismiss">
          <button
            onClick={() => setModalOpen(false)}
            className="info-modal-confirm-btn"
          >
            {I18n.t("javascript.statistics.utility.got_it")}
          </button>
        </div>
      )}
      {customActions && (
        <div className="info-modal-custom-actions">{customActions}</div>
      )}
    </Modal>
  );
}
