import { minBy, maxBy, omit, findIndex, find, pick } from "lodash-es";
import {dateToDayCode, dayCodeToISO, getWorkoutFamily, currentDayCode} from "./Utility";
import CloudService from "./CloudService";
import PubSub from "pubsub-js";
import $ from "jquery";
import Semaphore from 'semaphore-async-await';

const summaryLock = new Semaphore(1);
const sleepLock = new Semaphore(1);

const DataService = {
  userSummaryTarget: null,
  userDailySummaries: [],
  maxDailySummaryCodeLoaded: null,
  minDailySummaryCodeLoaded: null,
  minSleepDayCodeLoaded: null,
  maxSleepDayCodeLoaded: null,
  displayPreference: null,
  workoutTypeSummaries: null,
  summaryFileJsonData: {},
  timelineFileJsonData: {},
  userProfile: null,
  minDayBike: null,
  minDaySwim: null,
  minDayRun: null,
  minDayGym: null,
  minDaySleep: null,
  minDaySteps: null,
  heartRateZone: null,
  sleepSummaries: [],
  bindMessageBus: async function(faye_url, user_id) {
    console.log("BIND MESSAGE BUS");
    let dailySummaryChannel = `/user/${user_id}/user_daily_summary`;
    let workoutSummaryChannel = `/user/${user_id}/workout_summary`;
    let sleepSummaryChannel = `/user/${user_id}/sleep_summary`;
    let Faye = window.Faye;
    let client = new Faye.Client(faye_url);
    client.on("transport:up", function () {
      console.log("Message Bus transport up");
    });
    client.on("transport:down", function () {
      console.log("Message Bus transport down");
    });
    client.subscribe(workoutSummaryChannel, function (message) {
      console.log(
          `FAYE[Inbound Message] CHANNEL: ${workoutSummaryChannel} PAYLOAD: ${JSON.stringify(
              message
          )}`
      );
      DataService.updateWorkoutTypeSummary();
    });
    client.subscribe(dailySummaryChannel, function (message) {
      console.log(
        `FAYE[Inbound Message] CHANNEL: ${dailySummaryChannel} PAYLOAD: ${JSON.stringify(
          message
        )}`
      );
      DataService.updateWorkoutTypeSummary();
      DataService.updateSummary(
        message.user_daily_summary_id,
        message.day_code
      );
    });
    client.subscribe(sleepSummaryChannel, function (message) {
      console.log(
          `FAYE[Inbound Message] CHANNEL: ${sleepSummaryChannel} PAYLOAD: ${JSON.stringify(
              message
          )}`
      );
      DataService.updateSleepSummary(
        message.sleep_summary_id,
        message.day_code,
        message.action
      )
    })
  },
  loadDailySummaries: async function(count) {
    await summaryLock.acquire();
    console.log(
      `DataService[loadSummaries] minDailySummaryCodeLoaded: ${this.minDailySummaryCodeLoaded}`
    );
    if (this.minDailySummaryCodeLoaded <= 0) {
      console.log(`Nothing left in cloud`);
      summaryLock.release();
      return;
    } // nothing left in the cloud
    let records = await CloudService.getDailySummariesBeforeDate(
      dayCodeToISO(this.minDailySummaryCodeLoaded), count
    );
    if (records.length === 0) {
      this.minDailySummaryCodeLoaded = 0; // We have them all!
    } else {
      this.userDailySummaries = [...this.userDailySummaries, ...records];
      this.minDailySummaryCodeLoaded = minBy(
        this.userDailySummaries,
        "day_code"
      ).day_code;
      this.maxDailySummaryCodeLoaded = maxBy(
        this.userDailySummaries,
        "day_code"
      ).day_code;
    }
    summaryLock.release();
    console.log(
      `DataService[loadDailySummaries]: ${this.userDailySummaries.length} summaries`
    );
  },
  loadSleepSummaries: async function(count) {
    await sleepLock.acquire();
    console.log(
        `DataService[loadSleepSummaries] minSleepDayCodeLoaded: ${this.minSleepDayCodeLoaded}`
    );
    if (this.minSleepDayCodeLoaded <= 0){
      sleepLock.release();
      return;
    } // nothing left in the cloud
    let records = await CloudService.getSleepSummariesBeforeDate(
        dayCodeToISO(this.minSleepDayCodeLoaded), count
    );
    sleepLock.release();
    if (records.length === 0){
      this.minSleepDayCodeLoaded = 0;
    } else {
      this.sleepSummaries = [...this.sleepSummaries, ...records];
      this.minSleepDayCodeLoaded = minBy(
          this.sleepSummaries,
          "day_code"
      ).day_code;
    }
    console.log(
        `DataService[loadSleepSummaries]: ${this.sleepSummaries.length} sleepSummaries`
    );
  },
  updateUserSummaryTarget: async function(record) {
    if (JSON.stringify(record) === JSON.stringify(this.userSummaryTarget)) {
      console.log(
        `DataService[updateSummaryTarget] Skipping update.. no changes detected.`
      );
    } else {
      let sanitizedRecord = pick(record, [
        'card_settings',
        'daily_calories',
        'daily_steps',
        'weekly_active_time',
        'weekly_bike_distance',
        'weekly_run_distance',
        'weekly_swim_distance',
        'daily_sleep'
      ])
      this.userSummaryTarget = omit(
        await CloudService.updateUserSummaryTarget(sanitizedRecord),
        ["id", "user_id", "created_at", "updated_at"]
      );
    }
  },
  updateWorkoutTypeSummary: async function() {
    let newSummaries = await CloudService.getWorkoutTypeSummaries();
    this.groupWorkoutTypeSummaries(newSummaries);
    PubSub.publish("workout_type_summaries_changed");
  },
  refreshSummaries: async function(dayCode, count = 14) {
    // no check for dayCode against maxLoaded Pointer
    const afterDate = dayCodeToISO(dayCode);
    const summaries = await CloudService.getDailySummariesAfterDate(afterDate, count);
    summaries.forEach(async(summary) => {
      let index = findIndex(this.userDailySummaries, { id: summary.id });

      if (index >= 0) {
        this.userDailySummaries[index] = summary;
      } else {
        this.userDailySummaries.push(summary);
      }

      await DataService.updateSummaryFileJSON(dayCode, true);
      PubSub.publish("daycodechanged", { dayCode: summary.day_code });
    });
    this.maxDailySummaryCodeLoaded = maxBy(
        this.userDailySummaries,
        "day_code"
    ).day_code;
  },
  updateSummary: async function(summaryId, dayCode) {
    if (dayCode < this.minDailySummaryCodeLoaded) {
      return;
    } // ignore.. its not loaded yet
    let summary = await CloudService.getDailySummary(summaryId);
    let index = findIndex(this.userDailySummaries, { id: summaryId });
    if (index >= 0) {
      this.userDailySummaries[index] = summary;
    } else {
      this.userDailySummaries.push(summary);
    }
    await DataService.updateSummaryFileJSON(dayCode, true);
    PubSub.publish("daycodechanged", { dayCode: summary.day_code });
  },
  refreshSleepSummaries: async function(dayCode, count = 14) {
    // no check for dayCode against maxLoaded Pointer
    const afterDate = dayCodeToISO(dayCode);
    const summaries = await CloudService.getSleepSummariesAfterDate(afterDate, count);
    summaries.forEach(async(summary) => {
      let index = findIndex(this.sleepSummaries, { id: summary.id });

      if (index >= 0) {
        this.sleepSummaries[index] = summary;
      } else {
        this.sleepSummaries.push(summary);
      }

      summary.sleep_sessions.forEach(async(session) => {
        await DataService.updateSessionTimelineJSON(dayCode, session.id, session.timeline.url, true);
      });

      PubSub.publish("sleepsummarychanged", { dayCode: summary.day_code });
    });
    this.maxSleepDayCodeLoaded = maxBy(
        this.sleepSummaries,
        "day_code"
    ).day_code;
  },
  updateSleepSummary: async function(sleepSummaryId, dayCode, action) {
    if (dayCode < this.minSleepDayCodeLoaded) {
      return;
    } // ignore.. its not loaded yet
    if (action !== 'destroy'){
      let sleepSummary = await CloudService.getSleepSummary(sleepSummaryId);
      let index = findIndex(this.sleepSummaries, {id: sleepSummaryId});
      if (index >= 0) {
        this.sleepSummaries[index] = sleepSummary;
      } else {
        this.sleepSummaries.push(sleepSummary);
      }

      if (sleepSummary && sleepSummary.sleep_sessions) {
        sleepSummary.sleep_sessions.forEach(session => {
          delete this.timelineFileJsonData[session.id];
        });
      }

      PubSub.publish("sleepsummarychanged", {dayCode: sleepSummary.day_code});
    }
  },
  editSleepSummary: async function(id, rating) {
    let index = findIndex(this.sleepSummaries, {id: id});
    let sleepSummary = await CloudService.editSleepSummary(id, rating);
    this.sleepSummaries[index] = sleepSummary;
    return sleepSummary
  },
  getSteps: async function (startDayCode, endDayCode) {
    console.log(
      `DataService[getSteps] startDayCode: ${startDayCode} | endDayCode: ${endDayCode} | minDailySummaryCodeLoaded: ${this.minDailySummaryCodeLoaded}`
    );
    if (startDayCode < this.minDailySummaryCodeLoaded) {
      let count = endDayCode - startDayCode + 1
      await this.loadDailySummaries(count);
    }
    let steps = [];
    for (let i = startDayCode; i <= endDayCode; i++) {
      let summary = find(this.userDailySummaries, { day_code: i });
      let totalStrides = summary
        ? summary.swim_strides +
          summary.bike_strides +
          summary.run_strides +
          summary.gym_strides +
          summary.other_strides +
          summary.out_of_workout_strides
        : 0;
      steps.push({
        dayCode: i,
        totalSteps: totalStrides * 2,
        inWorkoutSteps: summary
          ? (totalStrides - summary.out_of_workout_strides) * 2
          : 0,
      });
    }
    return steps.sort((a, b) => b.dayCode - a.dayCode);
  },
  getCalories: async function(startDayCode, endDayCode) {
    console.log(
      `DataService[getSteps] startDayCode: ${startDayCode} | endDayCode: ${endDayCode} | minDailySummaryCodeLoaded: ${this.minDailySummaryCodeLoaded}`
    );
    if (startDayCode < this.minDailySummaryCodeLoaded) {
      let count = endDayCode - startDayCode + 1
      await this.loadDailySummaries(count);
    }
    let calories = [];
    for (let i = startDayCode; i <= endDayCode; i++) {
      let summary = find(this.userDailySummaries, { day_code: i });
      let totalCalories = summary
        ? summary.swim_calories +
          summary.bike_calories +
          summary.run_calories +
          summary.gym_calories +
          summary.other_calories +
          summary.out_of_workout_calories
        : 0;
      calories.push({
        dayCode: i,
        totalCalories: totalCalories,
        inWorkoutCalories: summary
          ? totalCalories - summary.out_of_workout_calories
          : 0,
      });
    }
    return calories.sort((a, b) => b.dayCode - a.dayCode);
  },
  getSleepSummaries: async function(startDayCode, endDayCode) {
    console.log(
        `DataService[getSleepSummaries] startDayCode: ${startDayCode} | endDayCode: ${endDayCode} | minSleepDayCodeLoaded: ${this.minSleepDayCodeLoaded}`
    );
    if(startDayCode < this.minSleepDayCodeLoaded) {
      let count = endDayCode - startDayCode + 1
      await this.loadSleepSummaries(count);
    }
    let sleepSummaries = []
    for (let i = startDayCode; i <= endDayCode; i++) {
      let sleepSummary = find(this.sleepSummaries, { day_code: i });
      sleepSummaries.push({
        dayCode: i,
        start_time: sleepSummary ? sleepSummary.start_time : null,
        end_time: sleepSummary ? sleepSummary.end_time : null,
        time_zone: null,
        score: null,
        heart_rate_avg: sleepSummary ? sleepSummary.heart_rate_avg : 0,
        rating: sleepSummary ? sleepSummary.rating : null,
        stage_0: sleepSummary ? sleepSummary.stage_0 : 0,
        stage_1: sleepSummary ? sleepSummary.stage_1 : 0,
        stage_2: sleepSummary ? sleepSummary.stage_2 : 0,
        stage_4: sleepSummary ? sleepSummary.stage_4 : 0,
        duration: sleepSummary ? sleepSummary.duration : 0,
        sleep_sessions: sleepSummary && sleepSummary.sleep_sessions ? sleepSummary.sleep_sessions : []
      });
    }
    return sleepSummaries.sort((a, b) => b.dayCode - a.dayCode);
  },
  getWorkoutData: async function(startDayCode, endDayCode, type) {
    console.log(
      `DataService[getWorkoutData] startDayCode: ${startDayCode} | endDayCode: ${endDayCode} | minDailySummaryCodeLoaded: ${this.minDailySummaryCodeLoaded}`
    );
    if (startDayCode < this.minDailySummaryCodeLoaded) {
      await this.loadDailySummaries();
    }
    let workoutData = [];
    for (let i = startDayCode; i <= endDayCode; i++) {
      let summary = find(this.userDailySummaries, { day_code: i });
      switch (type) {
        case "running":
          summary
            ? workoutData.push({
                dayCode: i,
                distance: summary.run_distance_m,
                time: summary.run_duration_ms,
                ascent: summary.run_ascent_m,
              })
            : workoutData.push({
                dayCode: i,
                distance: 0,
                time: 0,
                ascent: 0,
              });
          break;
        case "cycling":
          summary
            ? workoutData.push({
                dayCode: i,
                distance: summary.bike_distance_m,
                time: summary.bike_duration_ms,
                ascent: summary.bike_ascent_m,
              })
            : workoutData.push({
                dayCode: i,
                distance: 0,
                time: 0,
                ascent: 0,
              });
          break;
        case "swim":
          summary
            ? workoutData.push({
                dayCode: i,
                distance: summary.swim_distance_m,
                time: summary.swim_duration_ms,
                ascent: summary.swim_ascent_m,
              })
            : workoutData.push({
                dayCode: i,
                distance: 0,
                time: 0,
                ascent: 0,
              });
          break;
        case "strength":
          summary
            ? workoutData.push({
                dayCode: i,
                distance: summary.gym_distance_m,
                time: summary.gym_duration_ms,
                ascent: summary.gym_ascent_m,
                workoutCount: summary.gym_session_count,
              })
            : workoutData.push({
                dayCode: i,
                distance: 0,
                time: 0,
                ascent: 0,
                workoutCount: 0,
              });
          break;
        case "workout_time":
          let totalDistance = summary
            ? summary.run_distance_m +
              summary.bike_distance_m +
              summary.swim_distance_m +
              summary.gym_distance_m +
              summary.other_distance_m // are other_ classed metrics in-workout data?
            : 0;
          let totalTime = summary
            ? summary.run_duration_ms +
              summary.bike_duration_ms +
              summary.swim_duration_ms +
              summary.gym_duration_ms +
              summary.other_duration_ms // are other_ classed metrics in-workout data?
            : 0;
          let totalAscent = summary
            ? summary.run_ascent_m +
              summary.bike_ascent_m +
              summary.swim_ascent_m +
              summary.gym_ascent_m +
              summary.other_ascent_m // are other_ classed metrics in-workout data?
            : 0;
          let workoutCount = summary
            ? summary.run_session_count +
              summary.bike_session_count +
              summary.swim_session_count +
              summary.gym_session_count +
              summary.other_session_count
            : 0;
          workoutData.push({
            dayCode: i,
            distance: totalDistance,
            time: totalTime,
            ascent: totalAscent,
            workoutCount: workoutCount,
          });
          break;
        default:
          workoutData.push({
            dayCode: i,
            distance: 0,
            time: 0,
            ascent: 0,
          });
      }
    }
    return workoutData.sort((a, b) => b.dayCode - a.dayCode);
  },
  groupWorkoutTypeSummaries: function (workoutTypeSummaries) {
    workoutTypeSummaries.forEach((wts) => {
      wts.family = getWorkoutFamily(wts.workout_type_id);
      wts.distance_accum = parseInt(wts.distance_accum);
      wts.duration_total_accum = parseInt(wts.duration_total_accum);
      wts.is_time_workout = getWorkoutFamily(wts.workout_type_id) === "gym";
    });
    this.workoutTypeSummaries = workoutTypeSummaries;
  },
  updateSummaryFileJSON: async function(day_code, ignoreCache) {
    console.log("DataService[updateSummaryFileJSON] day code:", day_code);
    if (day_code < this.minDailySummaryCodeLoaded) {
      await this.loadDailySummaries();
    }
    if (DataService.summaryFileJsonData[day_code] && !ignoreCache) {
      console.log(
        "DataService[updateSummaryFileJSON] file already populated for day code:",
        day_code
      );
      return DataService.summaryFileJsonData;
    }
    let summary = find(this.userDailySummaries, { day_code: day_code });
    if (summary && summary.summary_file){
      try {
        const json = await $.getJSON(summary.summary_file.url)
        DataService.summaryFileJsonData[`${day_code}`] = json.minutes
      } catch (err) {
        console.log(
            "DataService[updateSummaryFileJSON] Request for summaryJSON failed: " +
            JSON.stringify(err)
        );
      }
    }
    return DataService.summaryFileJsonData;
  },
  updateSessionTimelineJSON: async function(day_code, session_id, url, ignoreCache) {
    if (day_code < this.minSleepDayCodeLoaded){
      await this.loadSleepSummaries();
    }
    if (DataService.timelineFileJsonData[session_id] && !ignoreCache){
      return DataService.timelineFileJsonData[session_id];
    }
    try {
      const json = await $.getJSON(url);
      DataService.timelineFileJsonData[session_id] = json;
      return json;
    } catch(err) {
      console.log("DataService[getSessionTimelineJSON] Request for timelineJSON failed: " + JSON.stringify(err));
    }
  },
  reload: function(count = 7) {
    DataService.setMaxDailySummaryDayCodeLoaded(DataService.maxDailySummaryCodeLoaded - 1);
    DataService.setMaxSleepDayCodeLoaded(DataService.maxSleepDayCodeLoaded - 1);

    DataService.refreshSummaries(DataService.maxDailySummaryCodeLoaded, 14);
    DataService.refreshSleepSummaries(DataService.maxSleepDayCodeLoaded, 14);
  },
  setUserProfile(user_profile){
    this.userProfile = user_profile
  },
  setUserSummaryTarget(user_summary_target){
    this.userSummaryTarget = user_summary_target
  },
  setUserDailySummaries(user_daily_summaries){
    this.userDailySummaries = user_daily_summaries
  },
  setMinDailySummaryDayCodeLoaded(min_day_code){
    this.minDailySummaryCodeLoaded = min_day_code
  },
  setMaxDailySummaryDayCodeLoaded(max_day_code){
    this.maxDailySummaryCodeLoaded = max_day_code
  },
  setMinSleepDayCodeLoaded(min_sleep_code){
    this.minSleepDayCodeLoaded = min_sleep_code
  },
  setMaxSleepDayCodeLoaded(max_day_code){
    this.maxSleepDayCodeLoaded = max_day_code
  },
  setDisplayPreference(display_preference){
    this.displayPreference = display_preference
  },
  setMinDayBike(min_day_bike){
    min_day_bike !== null ? this.minDayBike = min_day_bike : this.minDayBike = dateToDayCode(new Date())
  },
  setMinDaySwim(min_day_swim){
    min_day_swim !== null ? this.minDaySwim = min_day_swim : this.minDaySwim = dateToDayCode(new Date())
  },
  setMinDayRun(min_day_run){
    min_day_run !== null ? this.minDayRun = min_day_run : this.minDayRun = dateToDayCode(new Date())
  },
  setMinDayGym(min_day_gym){
    min_day_gym !== null ? this.minDayGym = min_day_gym : this.minDayGym = dateToDayCode(new Date())
  },
  setMinDaySleep(min_day_sleep){
    min_day_sleep !== null ? this.minDaySleep = min_day_sleep : this.minDaySleep = dateToDayCode(new Date())
  },
  setMinDaySteps(min_day_steps){
    min_day_steps !== null ? this.minDaySteps = min_day_steps : this.minDaySteps = dateToDayCode(new Date())
  },
  setHeartRateZone(heart_rate_zone){
    this.heartRateZone = heart_rate_zone
  },
  setSleepSummaries(sleep_summaries){
    this.sleepSummaries = sleep_summaries
  }
};

export default DataService;
