import React, { useContext, useEffect, useLayoutEffect } from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  withRouter,
} from "react-router-dom";
import SettingsPage from "./settings/SettingsPage";
import StatisticsPage from "./statistics/StatisticsPage";
import DataService from "./DataService";
import { omit } from "lodash-es";
import { Context } from "./Store";
import Navigation from "./Navigation";
import PageTransition from "./transition/PageTransition";
import './App.scss';

window.addEventListener('load', () => {
  document.querySelector(".react-loading")?.remove();
});

document.addEventListener("visibilitychange", function() {
  if (document.visibilityState === 'visible') {
    DataService.reload(7);
  }
});

function App() {
  const [state, dispatch] = useContext(Context);
  let location = useLocation();
  let history = useHistory();
  // Set our global react context
  const statsCards = DataService.userSummaryTarget;
  const targetGoals = omit(DataService.userSummaryTarget, [
    "id",
    "user_id",
    "created_at",
    "updated_at",
    "card_settings",
  ]);
  useEffect(() => {
    dispatch({ type: "set_stats_cards", payload: statsCards.card_settings });
    dispatch({ type: "set_target_goals", payload: targetGoals });
  }, []); // empty array will ensure effect is only run once

  useLayoutEffect(() => {
    let nav = document.getElementsByClassName("navigation");
    let doc = document.documentElement;
    if (nav) {
      let height = nav[0].offsetHeight;
      doc.style.setProperty("--nav-height", `${height}px`);
    }
  });

  return (
      <>
        <Navigation />
        <PageTransition
            preset="moveToLeftFromRight"
            transitionKey={location.pathname}
        >
          <Switch location={location}>
            <Route exact path="/" component={StatisticsPage} />
            <Route exact path="/settings" component={SettingsPage} />
          </Switch>
        </PageTransition>
      </>
  );
}
export default withRouter(App);
