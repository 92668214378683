import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";
import { dateToDayCode } from "./Utility";

const initialState = {
  statsCards: [],
  targetGoals: [],
  userDailySummaryUpdates: [],
  noInternet: false,
  activeHrIndex: dateToDayCode(new Date()),
  activeDailySleepIndex: dateToDayCode(new Date()),
};

// DO NOT pass global context objects as props to a React child
// initialize the state in a functional component like so: const [state, dispatch] = useContext(Context)
// now use your store variable => state.statsCards
// and call dispatches defined in Reducer.jsx on them => dispatch({ type: "setCards", payload: items });

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);
export default Store;
