import React, { useContext, useEffect, useState } from "react";
import {
  applyZones,
  currentDayCode,
  dayCodeToDate,
  msToTime,
} from "../../Utility";
import { Context } from "../../Store";
import HeartRateChart from "../HeartRateChart";
import DataService from "../../DataService";
import I18n from "../../Translation";

export default function HeartRateCard() {
  const [state, dispatch] = useContext(Context);
  const [graphLoading, setGraphLoading] = useState(false);
  const [zoneMinutes, setZoneMinutes] = useState([0, 0, 0, 0, 0]);
  let zoneColors = ["#FA000A", "#FB6300", "#FCEB00", "#39D848", "#0098CE"];

  useEffect(() => {
    let currentData = DataService.summaryFileJsonData[state.activeHrIndex];
    if (currentData) {
      let counts = applyZones(currentData, DataService.heartRateZone);
      setZoneMinutes(counts);
    }
  }, [graphLoading]);

  return (
    <div className="heart-rate-card card-body">
      <div className="card-top-info">
        <div className="card-top-info-date">
          <div
            onClick={() =>
              dispatch({
                type: "set_active_hr_index",
                payload: state.activeHrIndex - 1,
              })
            }
            className="card-top-info-button"
          >
            <img
              className="workout-icon"
              src="/static/left.svg"
              alt="previous"
              height={12}
            />
          </div>
          <div>{dayCodeToDate(state.activeHrIndex, true)}</div>
          <div
            onClick={
              state.activeHrIndex < currentDayCode()
                ? () =>
                    dispatch({
                      type: "set_active_hr_index",
                      payload: state.activeHrIndex + 1,
                    })
                : null
            }
            className="card-top-info-button"
          >
            {state.activeHrIndex < currentDayCode() && (
              <img
                className="workout-icon"
                src="/static/right.svg"
                alt="previous"
                height={12}
              />
            )}
          </div>
        </div>
      </div>
      <div className="hr-content">
        <div className="swiper-wrapper">
          <HeartRateChart
            graphLoading={graphLoading}
            setGraphLoading={setGraphLoading}
          />
        </div>
      </div>
      {DataService.heartRateZone && (
        <div className="workout-info">
          <div className="weekly-total">
            {I18n.t("javascript.statistics.statistics_page.time_in_zones")}
          </div>
          {zoneColors.map((obj, index) => {
            let zoneMs = zoneMinutes[index] * 60000;
            return (
              <div
                key={index}
                className={`info-item ${index !== 4 && "workouts"}`}
              >
                <div className="d-flex align-items-center">
                  {I18n.t("javascript.statistics.statistics_page.zone_number", {
                    number: Math.abs(index - 5),
                  })}
                </div>
                <div className="metric">{msToTime(zoneMs)}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
