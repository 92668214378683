import React, { useRef, useEffect, useState } from "react";
import DataService from "../DataService";
import BeatLoader from "./loaders/BeatLoader";
import SLEEP_COLORS from "./SleepColors";
export default function SleepSessionChart({ session }) {
  const [width, setWidth] = useState(0);
  const [timeline, setTimeline] = useState([]);
  const [loading, setLoading] = useState(true);
  const elementRef = useRef(null);
  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
  }, []);

  useEffect(() => {
    const getJSON = async () => {
      const res = await DataService.updateSessionTimelineJSON(
        session.day_code,
        session.id,
        session.timeline.url
      );
      setTimeline(res);
      setLoading(false);
    };
    getJSON();
  }, []);

  const getWidth = (blockDuration) => {
    let ratio = blockDuration / session.duration;
    return ratio * width;
  };

  const getHeight = (zone) => {
    let heights = { unspecified: 5, deep: 10, light: 20, rem: 30, awake: 40 };
    return heights[zone];
  };

  const getColor = (zone) => SLEEP_COLORS[zone].color;
  
  return (
    <div ref={elementRef} className={`sleep-session-chart`}>
      {timeline &&
        timeline.length > 0 &&
        timeline.map((entry, index) => {
          return (
            <div
              className="sleep-session-block"
              style={{
                width: `${getWidth(entry.duration_sec)}px`,
                height: `${getHeight(entry.zone)}px`,
                backgroundColor: `${getColor(entry.zone)}`,
              }}
              key={index}
            />
          );
        })}
      {loading &&
          <div className="loading-container">
            <BeatLoader />
          </div>
      }
    </div>
  );
}
