import React from "react";
import { stripTime } from "../Utility";

export default function SleepSessionTimes({
  session,
  start_time,
  end_time,
  start_date,
}) {
  const getTime = (date) => {
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };
  const Marker = ({ timeStr }) => {
    return (
      <div>
        <div className="sleep-dot" />
        <div>{timeStr}</div>
      </div>
    );
  };
  let hoursInSession = Math.round(session.duration / 3600);
  let middleDate = new Date(start_date);
  let quarterDate = new Date(start_date);
  let threeQuarterDate = new Date(start_date);
  quarterDate.setSeconds(session.duration * 0.25);
  threeQuarterDate.setSeconds(session.duration * 0.75);
  middleDate.setSeconds(session.duration * 0.5);
  return (
    <div className="d-flex justify-content-between">
      <div>
        <div
          style={{ position: "relative", right: "18%" }}
          className="sleep-dot"
        />
        <div>{stripTime(start_time)}</div>
      </div>
      {hoursInSession > 1 && (
        <Marker timeStr={stripTime(getTime(quarterDate))} />
      )}
      <Marker timeStr={stripTime(getTime(middleDate))} />
      {hoursInSession > 1 && (
        <Marker timeStr={stripTime(getTime(threeQuarterDate))} />
      )}
      <div>
        <div
          style={{ position: "relative", left: "15%" }}
          className="sleep-dot"
        />
        <div>{stripTime(end_time)}</div>
      </div>
    </div>
  );
}
