const Reducer = (state, action) => {
  switch (action.type) {
    case "set_stats_cards":
      return {
        ...state,
        statsCards: action.payload,
      };
    case "set_target_goals":
      return {
        ...state,
        targetGoals: action.payload,
      };
    case "set_user_daily_summary_updates":
      return {
        ...state,
        userDailySummaryUpdates: action.payload,
      };
    case "set_no_internet":
      return {
        ...state,
        noInternet: action.payload,
      };
    case "set_active_hr_index":
      return {
        ...state,
        activeHrIndex: action.payload,
      };
    case "set_active_daily_sleep_index":
      return {
        ...state,
        activeDailySleepIndex: action.payload,
      }
    default:
      return state;
  }
};

export default Reducer;
