import React, { useState, useContext, useCallback, useEffect } from "react";
import { currentDayCode, dayCodeToDate, sleepRatingToNumber } from "../Utility";
import DailySleepChart from "./DailySleepChart";
import { Context } from "../Store";
import InfoModal from "./InfoModal";
import I18n from "../Translation";
import DataService from "../DataService";
import SleepSessions from "./SleepSessions";

export default function DailySleep({ currentSleep, isDark }) {
  const [state, dispatch] = useContext(Context);
  const [howSleep, setHowSleep] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const decreaseDailyIndex = useCallback(() => {
    if (state.activeDailySleepIndex > DataService.minDaySleep) {
      if (state.activeDailySleepIndex - 1 < DataService.minSleepDayCodeLoaded) {
        setLoading(true);
        DataService.getSleepSummaries(
          state.activeDailySleepIndex - 1,
          state.activeDailySleepIndex - 7
        ).then((sleepSummaries) => {
          setLoading(false);
          dispatch({
            type: "set_active_daily_sleep_index",
            payload: state.activeDailySleepIndex - 1,
          });
        });
      } else if (
        state.activeDailySleepIndex - 1 >=
        DataService.minSleepDayCodeLoaded
      ) {
        dispatch({
          type: "set_active_daily_sleep_index",
          payload: state.activeDailySleepIndex - 1,
        });
      }
    }
  }, [state.activeDailySleepIndex]);
  const increaseDailyIndex = useCallback(() => {
    state.activeDailySleepIndex < currentDayCode() &&
      dispatch({
        type: "set_active_daily_sleep_index",
        payload: state.activeDailySleepIndex + 1,
      });
  }, [state.activeDailySleepIndex]);

  const getDisabled = () => {
    return !currentSleep || currentSleep.duration === 0;
  };
  const handleChange = (e) => {
    DataService.editSleepSummary(
      currentSleep.id,
      sleepRatingToNumber(e.target.value)
    ).then((response) => {
      //  response handled by DataService
    });
  };

  return (
    <div className="daily-sleep-card">
      <div className="card-top-info-date">
        <div onClick={decreaseDailyIndex} className="card-top-info-button">
          {state.activeDailySleepIndex > DataService.minDaySleep && (
            <img
              className="workout-icon"
              src="/static/left.svg"
              alt="previous"
              height={12}
            />
          )}
        </div>
        <div>{dayCodeToDate(state.activeDailySleepIndex, true)}</div>
        <div onClick={increaseDailyIndex} className="card-top-info-button">
          {state.activeDailySleepIndex < currentDayCode() && (
            <img
              className="workout-icon"
              src="/static/right.svg"
              alt="previous"
              height={12}
            />
          )}
        </div>
      </div>
      <DailySleepChart
        loading={loading}
        currentSleep={currentSleep}
        isDark={isDark}
      />
      <div className="sleep-info-row how-sleep">
        <div>{I18n.t("javascript.statistics.statistics_page.how_sleep")}</div>
        <select
          value={currentSleep && currentSleep.rating ? currentSleep.rating : ""}
          onChange={(e) => handleChange(e)}
          disabled={getDisabled()}
          className="info-row-input"
        >
          {currentSleep && currentSleep.duration > 0 && currentSleep.rating === null && (
            <option disabled value="">
              {I18n.t("javascript.statistics.utility.not_rated")}
            </option>
          )}
          {!getDisabled() && (
            <>
              <option value="excellent">
                {I18n.t("javascript.statistics.utility.excellent")}
              </option>
              <option value="great">
                {I18n.t("javascript.statistics.utility.great")}
              </option>
              <option value="good">
                {I18n.t("javascript.statistics.utility.good")}
              </option>
              <option value="fair">
                {I18n.t("javascript.statistics.utility.fair")}
              </option>
              <option value="poor">
                {I18n.t("javascript.statistics.utility.poor")}
              </option>
            </>
          )}
        </select>
      </div>
      {currentSleep && currentSleep.duration > 0 && currentSleep["sleep_sessions"] && (
        <SleepSessions sleepSessions={currentSleep["sleep_sessions"]} />
      )}
      {/* We will use this again when sleep scores are defined by SS*/}
      {/*<div className="sleep-info-row">*/}
      {/*  <div className="d-flex align-items-center">*/}
      {/*    <div className="sleep-score">*/}
      {/*      {I18n.t("javascript.statistics.statistics_page.sleep_score")}*/}
      {/*    </div>*/}
      {/*    <div className="sleep-info-toggle" onClick={() => setModalOpen(true)}>*/}
      {/*      <img*/}
      {/*        src="/static/info_circle.svg"*/}
      {/*        alt="previous"*/}
      {/*        height={16}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className="info-row">-</div>*/}
      {/*</div>*/}
      {(!currentSleep || currentSleep.duration === 0) && (
        <div className="sleep-info-row p-4 empty-description">
          {I18n.t(
            "javascript.statistics.statistics_page.empty_daily_sleep_description"
          )}
        </div>
      )}
      <InfoModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        title={I18n.t("javascript.statistics.statistics_page.sleep_score")}
      >
        <div className="p-4">
          {I18n.t(
            "javascript.statistics.statistics_page.sleep_score_description"
          )}
        </div>
      </InfoModal>
    </div>
  );
}
