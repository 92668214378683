import React from "react";
import I18n from "../Translation";
import SLEEP_COLORS from "./SleepColors";


export default function SleepHelpModal() {
  return (
    <div className="sleep-zones-help">
      <p>
        {I18n.t("javascript.statistics.statistics_page.sleep_info")}
      </p>
      {[SLEEP_COLORS.awake, SLEEP_COLORS.light, SLEEP_COLORS.deep, SLEEP_COLORS.rem, SLEEP_COLORS.unspecified].map(color => (
        <p className="sleep-zone-description" key={color.color}>
            <svg height="25" width="25">
              <circle cx="13" cy="13" r="10" fill={color.color} />
            </svg>

            {I18n.t(color.description)}
        </p>
      ))}
    </div>
  );
}