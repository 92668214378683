import React, { useContext, useState, useCallback } from "react";
import {
  currentDayCode,
  dayCodeToDate,
  getWeekRangeForDayCode,
  minutesToTime,
  getWeeklyAverageRating,
  numberToSleepRating,
} from "../Utility";
import { Context } from "../Store";
import WeeklySleepChart from "./WeeklySleepChart";
import InfoModal from "./InfoModal";
import I18n from "../Translation";
import DataService from "../DataService";
import { sumBy, pick, map, partialRight } from "lodash-es";

export default function WeeklySleep({ weeklySleep, isDark }) {
  let dayRatings = map(weeklySleep, partialRight(pick, ["rating"]));
  let averageRating = getWeeklyAverageRating(dayRatings);
  let emptyWeek =
    sumBy(weeklySleep, (o) => {
      return o.duration;
    }) === 0;
  const [state, dispatch] = useContext(Context);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // activeDailySleepIndex owns the state here.  Update the daily day_code to update the week range.
  let currentWeekRange = getWeekRangeForDayCode(state.activeDailySleepIndex);
  let daysMetGoal = 0;
  let weeklyAverageDuration = 0;
  if (!emptyWeek) {
    let dayCount = weeklySleep.filter((sleep) => sleep.duration > 0).length;
    let totalDuration = sumBy(weeklySleep, (o) => {
      return o.duration - o.stage_4;
    });
    weeklyAverageDuration = minutesToTime(totalDuration / dayCount / 60);
  }
  if (weeklySleep.length) {
    weeklySleep.forEach((item) => {
      if ((item.duration - item.stage_4) / 60 >= state.targetGoals.daily_sleep) {
        daysMetGoal++;
      }
    });
  }
  const getDayNumber = () => {
    const day = new Date().getDay();

    if ((currentDayCode() > currentWeekRange.last) || day === 0) {
      return 7
    }
    else {
      return day// wahoo weeks start on monday, "today's" sleep is yesterday, so this is correct
    }
  }

  const decreaseIndex = useCallback(() => {
    if (state.activeDailySleepIndex > DataService.minDaySleep) {
      if (state.activeDailySleepIndex - 7 < DataService.minSleepDayCodeLoaded) {
        setLoading(true);
        DataService.getSleepSummaries(
          state.activeDailySleepIndex - 1,
          state.activeDailySleepIndex - 7
        ).then((sleepSummaries) => {
          dispatch({
            type: "set_active_daily_sleep_index",
            payload: state.activeDailySleepIndex - 7,
          });
          setLoading(false);
        });
      } else if (
        state.activeDailySleepIndex - 7 >=
        DataService.minSleepDayCodeLoaded
      ) {
        dispatch({
          type: "set_active_daily_sleep_index",
          payload: state.activeDailySleepIndex - 7,
        });
      }
    }
  }, [state.activeDailySleepIndex]);
  const increaseIndex = useCallback(() => {
    if (currentWeekRange.last < currentDayCode()) {
      dispatch({
        type: "set_active_daily_sleep_index",
        payload: state.activeDailySleepIndex + 7,
      });
    }
  }, [state.activeDailySleepIndex]);

  return (
    <div className="weekly-sleep-card">
      <div className="card-top-info-date">
        <div onClick={decreaseIndex} className="card-top-info-button">
          {state.activeDailySleepIndex > DataService.minDaySleep && (
            <img
              className="workout-icon"
              src="/static/left.svg"
              alt="previous"
              height={12}
            />
          )}
        </div>
        <div>
          {dayCodeToDate(currentWeekRange.first, true, true)} -{" "}
          {dayCodeToDate(currentWeekRange.last, true, true)}
        </div>
        <div onClick={increaseIndex} className="card-top-info-button">
          {currentWeekRange.last < currentDayCode() && (
            <img
              className="workout-icon"
              src="/static/right.svg"
              alt="previous"
              height={12}
            />
          )}
        </div>
      </div>
      <WeeklySleepChart weeklySleep={weeklySleep} isDark={isDark} />
      {emptyWeek && (
        <div className="sleep-info-row p-4 empty-description">
          {I18n.t(
            "javascript.statistics.statistics_page.empty_weekly_sleep_description"
          )}
        </div>
      )}
      {!emptyWeek && (
        <>
          <div className="sleep-info-row">
            <div>
              {I18n.t("javascript.statistics.statistics_page.avg_sleep_rating")}
            </div>
            <div className="info-row">
              {typeof averageRating === "number"
                ? I18n.t(
                    `javascript.statistics.utility.${numberToSleepRating(
                      averageRating
                    )}`
                  )
                : "-"}
            </div>
          </div>
          <div className="sleep-info-row">
            <div>
              {I18n.t("javascript.statistics.statistics_page.avg_sleep_time")}
            </div>
            <div className="info-row">{weeklyAverageDuration}</div>
          </div>
          {/* This code will return when sleep scores are defined by SS*/}
          {/*<div className="sleep-info-row">*/}
          {/*  <div className="d-flex align-items-center">*/}
          {/*    <div className="sleep-score">*/}
          {/*      {I18n.t("javascript.statistics.statistics_page.sleep_score")}*/}
          {/*    </div>*/}
          {/*    <div*/}
          {/*      className="sleep-info-toggle"*/}
          {/*      onClick={() => setModalOpen(true)}*/}
          {/*    >*/}
          {/*      <img*/}
          {/*        src="static/info_circle.svg")}
          {/*        alt="previous"*/}
          {/*        height={16}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className="info-row">*/}
          {/*    {I18n.t("javascript.statistics.utility.na")}*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="sleep-info-row">
            <div>
              {I18n.t("javascript.statistics.statistics_page.sleep_goal")}
            </div>
            <div className="info-row">
              {state.targetGoals.daily_sleep
                ? I18n.t("javascript.statistics.statistics_page.sleep_nights", {
                    nights: `${daysMetGoal.toString()}`, days: `${getDayNumber()}`
                  })
                : "-"}
            </div>
          </div>
        </>
      )}
      <InfoModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        title="Sleep Score"
      >
        <div className="p-4">
          {I18n.t(
            "javascript.statistics.statistics_page.sleep_score_description"
          )}
        </div>
      </InfoModal>
    </div>
  );
}
