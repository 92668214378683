import React, { memo } from "react";
import { Transition, TransitionGroup } from "react-transition-group";
import { animations } from "./animations";
import { presets } from "./presets";
import { PageTransitionWrapper } from "./PageTransitionWrapper";

function PageTransition({
  children,
  enterAnimation: enterAnimationOverride,
  exitAnimation: exitAnimationOverride,
  preset,
  transitionKey,
  ...rest
}) {
  const selectEnterAnimation = () => {
    if (enterAnimationOverride) {
      if (typeof enterAnimationOverride === "string") {
        return animations[enterAnimationOverride];
      }
      return {
        ...animations[enterAnimationOverride.name],
        delay: enterAnimationOverride.delay,
        onTop: enterAnimationOverride.onTop,
      };
    }
    if (preset) {
      if (transitionKey === "/") {
        return {
          ...animations["moveFromLeft"],
          delay: presets[preset].exit.delay,
          onTop: presets[preset].exit.onTop,
        };
      }
      return {
        ...animations[presets[preset].enter.name],
        delay: presets[preset].enter.delay,
        onTop: presets[preset].enter.onTop,
      };
    }
    return "rotateSlideIn";
  };

  const selectExitAnimation = () => {
    if (exitAnimationOverride) {
      if (typeof exitAnimationOverride === "string") {
        return animations[exitAnimationOverride];
      }
      return {
        ...animations[exitAnimationOverride.name],
        delay: exitAnimationOverride.delay,
        onTop: exitAnimationOverride.onTop,
      };
    }
    if (preset) {
      if (transitionKey === "/settings") {
        return {
          ...animations["moveToRight"],
          delay: presets[preset].exit.delay,
          onTop: presets[preset].exit.onTop,
        };
      }
      return {
        ...animations[presets[preset].exit.name],
        delay: presets[preset].exit.delay,
        onTop: presets[preset].exit.onTop,
      };
    }
    return "rotateSlideIn";
  };

  let enterAnimation = selectEnterAnimation();
  let exitAnimation = selectExitAnimation();
  const timeout = Math.max(enterAnimation.duration, exitAnimation.duration);

  return (
    <div className="page-transition-group" {...rest}>
      <TransitionGroup component={null}>
        <Transition key={transitionKey} timeout={timeout}>
          {(state) => (
            <PageTransitionWrapper
              enterAnimation={enterAnimation}
              exitAnimation={exitAnimation}
              state={state}
              className="page-transition-wrapper"
            >
              {children}
            </PageTransitionWrapper>
          )}
        </Transition>
      </TransitionGroup>
    </div>
  );
}

export default memo(PageTransition);
