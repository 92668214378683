import React, { useContext, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import Card from "./Card";
import { Context } from "../Store";
import GoogleContext from "../GoogleContext";
import { usePageVisibility } from "react-page-visibility";
import DataService from "../DataService";
import PubSub from "pubsub-js";
import { currentDayCode } from "../Utility";
import PullToRefresh from 'react-simple-pull-to-refresh';
import ClipLoader from "./loaders/ClipLoader";
import InfoModal from "./InfoModal";
import I18n from "../Translation";

export default function StatisticsPage() {
  const [state, dispatch] = useContext(Context);
  const [google, setGoogle] = useState(null);
  const [modal, setModal] = useState({open: false});
  let displayItems = []; // DataService.userSummaryTarget.card_settings;
  if (state.statsCards.length) {
    displayItems = state.statsCards.filter((item) => item.show);
  }
  const [lastFocus, setLastFocus] = useState(new Date());
  const isVisible = usePageVisibility();

  const toggleModal = (newModalContent) => {
    if (newModalContent) {
      setModal({open: true, contents: newModalContent})
    } else {
      setModal({open: false})
    }
  }

  // import google chart Library once
  useEffect(() => {
    if (!google) {
      const head = document.head;
      let script = document.getElementById("googleChartsScript");
      if (!script) {
        script = document.createElement("script");
        script.src = "https://www.gstatic.com/charts/loader.js";
        script.id = "googleChartsScript";
        script.onload = () => {
          if (window.google && window.google.charts) {
            window.google.charts.load("current", { packages: ["corechart"] });
            window.google.charts.setOnLoadCallback(() =>
              setGoogle(window.google)
            );
          }
        };
        head.appendChild(script);
      } else if (window.google) {
        setGoogle(window.google);
      }
    }

    return () => {
      let script = document.getElementById("googleChartsScript");
      if (script) {
        script.remove();
      }
    };
  }, [google]);

  useEffect(() => {
    if (isVisible) {
      let difference = (new Date() - lastFocus) / 1000;
      if (difference > 60) {
        console.log(
          `[STATISTICS_PAGE]: In background for ${difference} seconds.  Requesting data.`
        );
        DataService.updateWorkoutTypeSummary();
        PubSub.publish("daycodechanged", { dayCode: currentDayCode() });
        PubSub.publish("sleepsummarychanged", { dayCode: currentDayCode() });
      }
      setLastFocus(new Date());
    }
  }, [isVisible]);

  const onRefresh = () => {
    DataService.reload(7);

    return new Promise((resolve) => {
      setTimeout(resolve, 1200);
    });
  };

  return (
    <GoogleContext.Provider value={{ google, setGoogle }}>
      <div className="app page statistics-container">
        <div className="content">
          <div className="content-scroll">
            <PullToRefresh onRefresh={onRefresh} refreshingContent={<ClipLoader />} pullingContent={''}>
              {displayItems.length > 0 &&
                displayItems.map((item, index) => (
                  <Card key={index} title={item.name} setModal={toggleModal} />
                ))}
              {displayItems.length === 0 && (
                <div className="no-display-items" />
              )}
              <InfoModal
                modalOpen={modal.open}
                setModalOpen={toggleModal}
                title={I18n.t("javascript.statistics.statistics_page.sleep")}
              >
                {modal.contents}
              </InfoModal>
            </PullToRefresh>
          </div>
        </div>
      </div>
    </GoogleContext.Provider>
  );
}
